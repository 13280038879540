import React, { useState } from 'react';
import {
  Button,
  Grid,
  InputLabel,
  TextField,
  InputAdornment,
  DialogContent,
  Dialog,
  DialogActions,
} from '@material-ui/core';
import { Formik, Form } from 'formik';
import { projectGroupRouteRef, rootRouteRef } from '../../routes';
import { useRouteRef } from '@backstage/core-plugin-api';
import { ProjectOwners } from '../../pages/ProjectDetails/ProjectOwners';
import { NetPromoterScore, PageLayout } from '@internal/sg-ui-kit';
import {
  useApi,
  microsoftAuthApiRef,
  errorApiRef,
} from '@backstage/core-plugin-api';
import { useAsyncFn } from 'react-use';
import { projectApiRef } from '../../api';
import { useParams } from 'react-router-dom';
import { Progress } from '@backstage/core-components';
import { useNavigate } from 'react-router-dom';
import { isResponseStatus2XX } from '@internal/sg-utils-common';
import { usePermissions } from '@internal/plugin-projects';
import { useStyles } from './styles';

export const CreateUserGroups = () => {
  const navigate = useNavigate();
  const { projectId } = useParams() as { projectId: string };
  const projectApi = useApi(projectApiRef);
  const authref = useApi(microsoftAuthApiRef);
  const rootLink = useRouteRef(rootRouteRef);
  const projectGroupRoute = useRouteRef(projectGroupRouteRef);
  const errorApi = useApi(errorApiRef);
  const [tokenData, setTokenData] = useState('');
  const [projectName, setProjectName] = useState('');
  const [projectDescription, setProjectDescription] = useState('');
  const [open, setOpen] = useState(false);
  const [nameDirty, setNameDirty] = useState(false);
  const [newUserGroupID, setNewUserGroupID] = React.useState<string>('');
  const [dialogOpenNPS, setDialogOpenNPS] = React.useState<boolean>(false);
  const { refresh } = usePermissions();

  const dialogOnCloseHelper: () => void = () => {
    setDialogOpenNPS(false);
    refresh();
    navigate(
      projectGroupRoute({
        projectId: projectId,
        usergroup: 'usergroup',
        groupId: newUserGroupID,
      }),
    );
  };
  const classes = useStyles();

  const initialValues = {
    user_group_name: '',
    project_description: '',
    accessCheckbox: [],
  };

  // Back to Projects list page
  const backToTarget = projectId
    ? `/projects/${projectId}?active=1`
    : rootLink();
  const backToLink = React.useMemo(
    () => ({
      to: backToTarget,
      label: projectId ? 'Back to Project' : 'Back to Projects',
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [backToTarget],
  );

  // Success dailog box
  const handleDialogOpen = () => {
    setOpen(true);
  };

  const handleDialogClose = (reason: any) => {
    if (reason.type === 'click') {
      setOpen(false);
      setDialogOpenNPS(true);
    }
  };

  // To get perticular project details based on projectID
  const [{ value: data = [] as any, loading, error }, fetchContent] =
    useAsyncFn(async () => {
      const idToken = await authref.getIdToken();
      setTokenData(idToken);
      const params = {
        idToken: idToken,
        manipulators: ['resources'],
      };
      const projectData = await projectApi.getProjectByID(projectId, params);
      const code = projectData?.response?.status;
      if (code >= 400) {
        const errorMsg = projectData?.response?.data?.message;
        errorApi.post(new Error(`${errorMsg}`));
      }
      return projectData;
    }, [projectId]);

  React.useEffect(
    () => {
      fetchContent();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  if (loading) {
    return <Progress />;
  }
  if (error) {
    errorApi.post(new Error(`${error}`));
  }

  const onPost = async () => {
    try {
      const rsp: any = await projectApi.addProjectUserGroupData(
        {
          idToken: tokenData,
          members: [''],
          name: projectName.toLowerCase() || '',
          description: projectDescription,
          accessible_resources: [] as any,
        },
        projectId,
      );

      const code = rsp?.response?.status;
      if (isResponseStatus2XX(code)) {
        setNewUserGroupID(rsp.response?.data?.id);
        handleDialogOpen();
      } else {
        const errorMsg = rsp?.response?.data?.message;
        errorApi.post(new Error(`${errorMsg}`));
      }
    } catch (e) {
      errorApi.post(new Error(`${e.message}`));
    }
  };

  // Calling the create new user group api
  const onSubmit = () => {
    onPost();
  };

  return (
    <>
      <PageLayout
        type="entity"
        title={data?.response?.data?.name}
        headerAdditionalControls={
          <ProjectOwners owners={data?.response?.data?.owners as any} />
        }
        backToLink={backToLink}
        children={undefined}
      />
      <div className={classes.container}>
        <h1>Create User Group</h1>
        <Grid container spacing={2} className={classes.createUserGroupSection}>
          <Grid item xs={12}>
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              enableReinitialize
            >
              {formik => {
                setProjectName(
                  `${data?.response?.data?.key}-${formik.values.user_group_name}`,
                );
                setProjectDescription(formik.values.project_description);
                return (
                  <Form>
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="user_group_name"
                        style={{
                          color:
                            (formik.values.user_group_name.length < 1 &&
                              nameDirty) ||
                            (!formik.values.user_group_name.match(
                              '^([a-z0-9]+-)*[a-z0-9]+$',
                            ) &&
                              nameDirty) ||
                            (data?.response?.data?.key.length +
                              formik.values.user_group_name.length >
                              31 &&
                              nameDirty)
                              ? 'red'
                              : 'black',
                        }}
                      >
                        User Group Name*
                      </InputLabel>
                      <TextField
                        style={{ paddingBottom: '0%' }}
                        type="text"
                        id="user_group_name"
                        data-testid="user_group_name"
                        name="user_group_name"
                        required
                        error={
                          (formik.values.user_group_name.length < 1 &&
                            nameDirty) ||
                          (!formik.values.user_group_name.match(
                            '^([a-z0-9]+-)*[a-z0-9]+$',
                          ) &&
                            nameDirty) ||
                          (data?.response?.data?.key.length +
                            formik.values.user_group_name.length >
                            31 &&
                            nameDirty)
                        }
                        onChange={formik.handleChange}
                        value={formik.values.user_group_name}
                        onClick={() => setNameDirty(true)}
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <InputAdornment
                              style={{
                                textTransform: 'lowercase',
                                marginLeft: '0',
                              }}
                              position="end"
                            >
                              {`${data?.response?.data?.key}-`}
                            </InputAdornment>
                          ),
                        }}
                        helperText={`Provide a unique name for your user group. Only lower case letters, numbers and single-hyphens are allowed. Does not end with hyphen. Max. no. of characters allowed = 32 (including project key - ${`${data?.response?.data?.key}-`})`}
                      />
                    </Grid>
                    <br />
                    <Grid item xs={6}>
                      <InputLabel htmlFor="project_description">
                        Description
                      </InputLabel>
                      <TextField
                        type="text"
                        id="project_description"
                        name="project_description"
                        data-testid="project_description"
                        onChange={formik.handleChange}
                        value={formik.values.project_description}
                        rows={4}
                        multiline
                        fullWidth
                        helperText="Provide a brief description of the user group you are creating. "
                        placeholder="Example : To manage users who need developer rights to various software tools and are a part of the TPM project."
                      />
                    </Grid>
                    <br />
                    <Grid item xs={6}>
                      <div style={{ paddingTop: '5%' }}>
                        <Grid container spacing={2}>
                          <Grid item xs={1}>
                            <Button
                              data-testid="cancel-ug-submit-button"
                              variant="outlined"
                              type="reset"
                              onClick={() => navigate(`/projects/${projectId}`)}
                            >
                              Cancel
                            </Button>
                          </Grid>
                          <Grid item xs={2} />
                          <Grid item xs={1}>
                            <Button
                              id="create-ug-submit-button"
                              data-testid="create-ug-submit-button"
                              variant="contained"
                              type="submit"
                              disabled={
                                formik.values.user_group_name.length < 1 ||
                                !formik.values.user_group_name.match(
                                  '^([a-z0-9]+-)*[a-z0-9]+$',
                                ) ||
                                data?.response?.data?.key.length +
                                  formik.values.user_group_name.length >
                                  31
                              }
                            >
                              Create
                            </Button>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </Grid>
        </Grid>
        <Dialog open={open} onClose={handleDialogClose} maxWidth="xs">
          <DialogContent
            id="ug-create-confirmed-dialog"
            style={{ fontWeight: 'bold' }}
          >
            New user group created. It may take 15 minutes or so to be fully
            usable.
          </DialogContent>
          <DialogActions style={{ justifyContent: 'center' }}>
            <Button
              variant="contained"
              size="small"
              onClick={(reason: any) => handleDialogClose(reason)}
              style={{ backgroundColor: 'black' }}
              id="dialog-close-button"
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      {dialogOpenNPS && (
        <NetPromoterScore
          npsLocation="user-journey"
          userJourney="ProjectOwner_CreateUserGroup_v1"
          dialogOpen={dialogOpenNPS}
          dialogOnCloseHelper={dialogOnCloseHelper}
        />
      )}
    </>
  );
};
