import React, { useState } from 'react';
import { PageLayout, Tabs } from '@internal/sg-ui-kit';
import { useAsyncFn } from 'react-use';
import {
  useApi,
  microsoftAuthApiRef,
  errorApiRef,
} from '@backstage/core-plugin-api';

import { useParams } from 'react-router-dom';
import { ProjectOwners } from '../../pages/ProjectDetails/ProjectOwners';
import { rootRouteRef } from '../../routes';
import { useRouteRef, identityApiRef } from '@backstage/core-plugin-api';
import { projectApiRef } from '../../api';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  TextField,
  OutlinedInput,
  Select,
  FormControl,
  Switch,
  Tooltip,
} from '@material-ui/core';
import { ChipInput } from 'material-ui-formik-components';
import { Field, FieldArray, Formik, Form } from 'formik';
import { Progress } from '@backstage/core-components';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircle from '@material-ui/icons/AddCircle';
import { useNavigate } from 'react-router-dom';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {
  getCompaniesList,
  getTechDocsLink,
  isValidUrl,
} from 'sg-utils-frontend';
import { URL_CUE } from 'usg-types';
import {
  PROJECT_MGMT_NO_MGMT,
  PROJECT_MGMT_TYPE_OWNERS,
  PROJECT_MGMT_TYPE_OWNERS_END_USERS,
} from '../../common/SGConstants';
import { initialValues, createInitialValues } from './initialValues';
import { useCompaniesApi } from '@internal/plugin-projects';
import { rootRouteRef as welcomeRouteRef } from '@internal/plugin-welcome-page';
import { AlertModal } from '../AlertModal';
import { ProjectRoleManagement } from './ProjectRoleManagement';
import { usePermissions } from '@internal/plugin-projects';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import { Link } from 'react-router-dom';
import { useStyles } from './styles';

interface ViewEditProjectComponentProps {
  projectId: string;
}

export const ViewEditProjectComponent = ({
  projectId,
}: ViewEditProjectComponentProps) => {
  const classes = useStyles();
  const rootLink = useRouteRef(rootRouteRef);
  const welcomePageRoute = useRouteRef(welcomeRouteRef);
  const identityApi = useApi(identityApiRef);
  const [onEdit, setOnEdit] = useState(false);
  const [onEditSelfService, setOnEditSelfService] = useState(false);
  const [onCreate, setOnCreate] = useState(false);
  const [idTokenData, setIdTokenData] = useState([] as any);
  const [projectData, setProjectData] = useState([] as any);
  const [open, setOpen] = useState(false);
  // const [ipduplicate, setIPDuplicate] = useState(false);
  // const [ccduplicate, setCCDuplicate] = useState(false);
  const [managementType, setManagementType] = useState(false);
  const [ipOwnerError] = useState(false);
  const [collaboratingError] = useState(false);

  // const [hundredCheck, setHundredCheck] = useState(false);
  // const [editCCErrorList, setEditCCErrorList] = useState([]);
  // const [editOwnersListEValue, setEditOwnersListEValue] = useState([]);

  // Create Project Errors
  const [projectNameError, setProjectNameError] = useState(false);
  const [projectKeyError, setProjectKeyError] = useState(false);
  const [managementTypeError, setManagementTypeError] = useState(false);
  const [ownersListError, setOwnersListError] = useState(false);
  const [createIPError, setCreateIPError] = useState(false);
  const [ownersListEValue, setOwnersListEValue] = useState([]);
  const [createHundredCheck, setCreateHundredCheck] = useState(false);
  const [createCCError, setCreateCCError] = useState(false);
  const [createCCErrorList, setCreateCCErrorList] = useState([]);
  const [createButtonStatus, setCreateButtonStatus] = useState(false);
  const [openSuccessCreate, setOpenSuccessCreate] = useState(false);
  const [selfServiceData, setSelfServiceData] = useState([] as any);
  const [activeTab, setActiveTab] = useState(0);
  const {
    allCompanies,
    isSuccess: allCompaniesSuccess,
    message: allCompaniesMessage,
    isLoading: isLoadingCompanies,
  } = useCompaniesApi();
  const [participantCompanies, setParticipantCompanies] = useState([] as any);
  const [ipOwnerCompanies, setIpOwnerCompanies] = useState([] as any);

  const authref = useApi(microsoftAuthApiRef);
  const projectApi = useApi(projectApiRef);
  const errorApi = useApi(errorApiRef);
  const navigate = useNavigate();
  const [selfService, setSelfService] = React.useState(true);
  const [selfServiceEditSwitch, setSelfServiceEditSwitch] =
    React.useState(true);
  const { isAdmin, isProjectOwner } = usePermissions();

  const handleChange = (event: any) => {
    setSelfServiceEditSwitch(event.target.checked);
  };
  const backToTarget = projectId ? `/projects/${projectId}` : rootLink();
  const backToLink = React.useMemo(
    () => ({
      to: backToTarget,
      label: projectId ? 'Back to Project' : 'Back to Projects',
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [backToTarget],
  );

  const [{ value: data = [], loading, error }, fetchContent] =
    useAsyncFn(async (): Promise<any> => {
      const token = await authref.getIdToken();
      const params = {
        idToken: token,
        manipulators: ['participant_companies'],
      };
      setIdTokenData(token);

      const projectDataRes = await projectApi.getProjectByID(projectId, params);
      setProjectData(projectDataRes);

      const code = projectDataRes?.response?.status;
      if (code === 401 || code === 404 || code === 403) {
        const errorMsg = projectDataRes?.response?.data?.message;
        errorApi.post(new Error(`${errorMsg}`));
      }

      if (code === 200) {
        const profileInfo = await authref.getProfile();
        const profileParams = {
          search_term: [profileInfo?.email],
        };

        const profileData = await projectApi.getUserUserGroupsSearchData(
          profileParams,
        );
        const errorCode = profileData?.response?.status;
        if (
          errorCode === 400 ||
          errorCode === 401 ||
          errorCode === 404 ||
          errorCode === 403
        ) {
          const errorMsg = profileData?.response?.data?.message;
          errorApi.post(new Error(`${errorMsg}`));
        }
      }
      return projectDataRes;
    }, []);

  const fetchSelfServiceData = async () => {
    try {
      const res: any = await projectApi.getOptOutData(parseInt(projectId, 10));
      if (Object.keys(res).length > 0) {
        setSelfServiceData(res);
        setSelfServiceEditSwitch(res?.enable_self_service);
        setSelfService(res?.enable_self_service);
      } else {
        setSelfServiceEditSwitch(true);
      }
    } catch (err: any) {
      errorApi.post(new Error(err?.message));
    }
  };

  React.useEffect(
    () => {
      if (!projectId) {
        setOnCreate(true);
      } else {
        fetchContent();
        fetchSelfServiceData();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  React.useEffect(() => {
    try {
      if (data?.response?.data && allCompanies) {
        const participantsList = getCompaniesList(
          data?.response?.data?.participant_companies,
          allCompanies,
        );
        const ipOwnersList = getCompaniesList(
          data?.response?.data?.ip_owner_companies,
          allCompanies,
        );
        setParticipantCompanies(participantsList);
        setIpOwnerCompanies(ipOwnersList);
      }
    } catch (err) {
      errorApi.post(new Error(err?.message));
    }
  }, [data, allCompanies, errorApi]);

  if (loading) {
    return <Progress />;
  }

  if (error) {
    errorApi.post(new Error(error.message));
    navigate('/projects');
  }

  if (!allCompaniesSuccess && !isLoadingCompanies) {
    return (
      <AlertModal
        alertMessage={allCompaniesMessage}
        isModalOpen={!allCompaniesSuccess}
        onClose={() => {
          navigate(welcomePageRoute());
        }}
      />
    );
  }

  const createProject = async (value: any) => {
    const createDataResp = await projectApi.createProject(value);
    const code = createDataResp?.response?.status;
    if (code === 400 || code === 401 || code === 404 || code === 403) {
      setCreateButtonStatus(false);
      const createError = createDataResp?.response?.data?.message;
      errorApi.post(new Error(`${createError}`));
    }
    if (code === 201) {
      setOpenSuccessCreate(true);
    }
  };

  const handleDialogOpen = () => {
    setOpen(true);
  };

  const onSubmit = async (value: any) => {
    const editData = await projectApi.editById(projectId, {
      idToken: idTokenData,
      name: data?.response?.data?.name,
      description: value?.values?.project_description,
      management_type: value?.values?.management_type,
      owners: data?.response?.data?.owners,
      ip_owner_companies: data?.response?.data?.ip_owner_companies,
      participant_companies: data?.response?.data?.participant_companies,
    });
    const code = editData?.response?.status;
    if (code === 400 || code === 401 || code === 404 || code === 403) {
      const errorMsg = editData?.response?.data?.message;
      errorApi.post(new Error(`${errorMsg}`));
    }
    if (code === 200) {
      handleDialogOpen();
      setActiveTab(0);
      setOnEdit(false);
    }
  };

  const onCheckForm = (value: any) => {
    if (value?.values?.management_type === undefined || '') {
      setManagementType(true);
    } else {
      onSubmit(value);
    }
  };

  const handleSaveSelfServiceForm = async (value: any) => {
    const profile = await identityApi.getProfileInfo();
    const editData = (await projectApi.upsertOptOutData(
      parseInt(projectId, 10),
      {
        user_email: profile?.email,
        enable_self_service: selfServiceEditSwitch,
        url_link: value?.values?.link,
        instructions: value?.values?.access_instructions,
      },
    )) as any;
    if (editData) {
      handleDialogOpen();
      setActiveTab(1);
      setOnEditSelfService(false);
    }
  };

  const handleCancelEditHandler = () => {
    // upon cancel edit, reset edit form and switch to it's backend value
    setOnEditSelfService(false);
    setSelfServiceEditSwitch(selfService);
  };

  // logic for create project, which not in use anymore
  // const onCreateCheckForm = (value: any) => {
  //   setCreateButtonStatus(true);
  //   let isCreateError = false;
  //   if (
  //     value.form.values.project_name === '' ||
  //     value.form.values.project_name.length < 2
  //   ) {
  //     isCreateError = true;
  //     setProjectNameError(true);
  //   }
  //   if (
  //     value.form.values.project_key === '' ||
  //     value.form.values.project_key.length < 2 ||
  //     value.form.values.project_key.length > 8
  //   ) {
  //     isCreateError = true;
  //     setProjectKeyError(true);
  //   }
  //   if (value.form.values.management_type === '') {
  //     isCreateError = true;
  //     setManagementTypeError(true);
  //   }
  //   if (value.form.values.primary_owners.length === 0) {
  //     isCreateError = true;
  //     setOwnersListError(true);
  //   }

  //   const companyIPlist = [] as any;
  //   const companyIPlistError = [] as any;
  //   let companyPercentage = 0;

  //   value.form.values.IP_Owner_Companies.forEach((element: any, index: any) => {
  //     if (element.company_id) {
  //       companyIPlist.push(element.company_id);
  //     } else {
  //       isCreateError = true;
  //       companyIPlistError[index] = 'Company Name Cannot be Empty';
  //     }
  //     if (element.percentage) {
  //       companyPercentage += element.percentage;
  //     } else {
  //       setCreateHundredCheck(true);
  //       isCreateError = true;
  //     }
  //   });

  //   const duplicateIndexes = (arr: any, el: any) => {
  //     const duplicate = [];
  //     for (let i = 0; i < arr.length; i++) {
  //       if (arr[i] === el) {
  //         duplicate.push(i);
  //       }
  //     }
  //     return duplicate;
  //   };
  //   companyIPlist.forEach((element: any) => {
  //     const duplicateIndex = duplicateIndexes(companyIPlist, element);
  //     if (duplicateIndex.length > 1) {
  //       duplicateIndex.forEach(indx => {
  //         companyIPlistError[indx] = 'Company Name Cannot be identical';
  //         isCreateError = true;
  //       });
  //     }
  //   });
  //   if (companyIPlistError.length > 0) {
  //     setOwnersListEValue(companyIPlistError);
  //     setCreateIPError(true);
  //   }

  //   if (companyPercentage !== 100) {
  //     setCreateHundredCheck(true);
  //     isCreateError = true;
  //   }

  //   const companyCClist = [] as any;
  //   const companyCClistError = [] as any;
  //   value.form.values.Collaborating_Companies.forEach(
  //     (element: any, index: any) => {
  //       if (element.company_id) {
  //         companyCClist.push(element.company_id);
  //       } else {
  //         isCreateError = true;
  //         companyCClistError[index] = 'Company Name Cannot be Empty';
  //       }
  //     },
  //   );
  //   companyCClist.forEach((element: any) => {
  //     const duplicateIndex = duplicateIndexes(companyCClist, element);
  //     if (duplicateIndex.length > 1) {
  //       duplicateIndex.forEach(indx => {
  //         companyCClistError[indx] = 'Company Name Cannot be identical';
  //         isCreateError = true;
  //       });
  //     }
  //   });
  //   if (companyCClistError.length > 0) {
  //     setCreateCCErrorList(companyCClistError);
  //     setCreateCCError(true);
  //   }
  //   if (!isCreateError) {
  //     const finalData: any = {};
  //     finalData.idToken = idTokenData;
  //     finalData.key = value.form.values.project_key.toUpperCase();
  //     finalData.name = value.form.values.project_name;
  //     finalData.description = value.form.values.project_description;
  //     finalData.management_type = value.form.values.management_type;
  //     finalData.owners = [];
  //     finalData.ip_owner_companies = [];
  //     finalData.participant_companies = [];
  //     for (const email of value.form.values.primary_owners) {
  //       finalData.owners.push({ user_email: email, role: 'PRIMARY' });
  //     }
  //     for (const email of value.form.values.project_owners) {
  //       finalData.owners.push({ user_email: email, role: 'SECONDARY' });
  //     }

  //     for (const company of value.form.values.IP_Owner_Companies) {
  //       const ipData: any = {
  //         company_id: company.company_id,
  //         percentage: company.percentage,
  //       };
  //       if (typeof company.started_on !== 'string') {
  //         ipData.started_on = company?.started_on?.toISOString();
  //       }
  //       if (typeof company.ended_on !== 'string') {
  //         ipData.ended_on = company?.ended_on?.toISOString();
  //       }
  //       finalData.ip_owner_companies.push(ipData);
  //     }

  //     for (const company of value.form.values.Collaborating_Companies) {
  //       const ccData: any = {
  //         company_id: company.company_id,
  //       };
  //       if (typeof company.started_on !== 'string') {
  //         ccData.started_on = company?.started_on?.toISOString();
  //       }
  //       if (typeof company.ended_on !== 'string') {
  //         ccData.ended_on = company?.ended_on?.toISOString();
  //       }
  //       finalData.participant_companies.push(ccData);
  //     }
  //     createProject(finalData);
  //   } else {
  //     setCreateButtonStatus(false);
  //   }
  // };

  const handleDialogClose = () => {
    setOpen(false);
    fetchContent();
    fetchSelfServiceData();
  };

  const handleEditSelfService = () => {
    setOnEditSelfService(!onEditSelfService);
    fetchSelfServiceData();
  };

  const handleCreationDialogClose = () => {
    setOpenSuccessCreate(false);
    navigate('/projects');
  };

  const managementTypeData = (type: any) => {
    switch (type) {
      case PROJECT_MGMT_NO_MGMT:
        return 'No Management';
      case PROJECT_MGMT_TYPE_OWNERS:
        return 'Project Owners';
      case PROJECT_MGMT_TYPE_OWNERS_END_USERS:
        return 'Project Owners and End Users';
      default:
        return '';
    }
  };
  const projectDetailsTabs = [
    {
      label: 'PROJECT DETAILS',
      value: 'project_details',
      content: (
        <div>
          {!onEdit ? (
            <Grid container>
              <Grid item xs={6}>
                <>
                  <h4>{data?.response?.data?.name || null}</h4>
                  <p>{data?.response?.data?.description || null}</p>

                  <h4 style={{ paddingTop: '4%' }}>Management Type</h4>
                  <p style={{ fontSize: '90%' }}>
                    {managementTypeData(data?.response?.data?.management_type)}
                  </p>

                  <h4 style={{ paddingTop: '4%' }}>IP Owner Companies</h4>
                  <table
                    style={{ emptyCells: 'show' }}
                    data-testid="ip-owner-companies-tableview"
                  >
                    <tr style={{ textAlign: 'left' }}>
                      <th>Company Name</th>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <th>Start Date</th>
                      <td>&nbsp;</td>

                      <th>End Date</th>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <th>Percentage</th>
                    </tr>
                    {ipOwnerCompanies.map((res: any) => {
                      return (
                        <>
                          <tr style={{ textAlign: 'left' }}>
                            <p>{res.name}</p>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td style={{ fontSize: '90%' }}>
                              {res.started_on
                                ? `${new Date(
                                    res?.started_on,
                                  ).toLocaleDateString()}`
                                : null}
                            </td>
                            {res?.ended_on ? (
                              <td>- &nbsp; </td>
                            ) : (
                              <td> &nbsp; </td>
                            )}
                            <td style={{ fontSize: '90%' }}>
                              {res.ended_on
                                ? `${new Date(
                                    res?.ended_on,
                                  ).toLocaleDateString()}`
                                : null}
                            </td>

                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>{`${res.percentage}%`}</td>
                          </tr>
                        </>
                      );
                    })}
                  </table>
                  <Grid
                    container
                    style={{ paddingTop: '4%', flexWrap: 'nowrap' }}
                    alignItems="center"
                  >
                    <h4>
                      Collaborating Companies (including subcontracting
                      companies)
                    </h4>

                    <Tooltip
                      classes={{
                        tooltip: classes.tooltip,
                        arrow: classes.tooltipArrow,
                      }}
                      arrow
                      placement="right"
                      title={
                        <div style={{ textAlign: 'center' }}>
                          All members of Stargate project workspaces must belong
                          to one of the workspace's registered Collaborating
                          Companies. If you need to add members from another
                          company,{' '}
                          <Link
                            className={classes.hyperlink}
                            target="_blank"
                            to={`${getTechDocsLink(
                              'add-collaborating-companies',
                            )}#add-collaborating-companies-to-project-workspace-procedure`}
                          >
                            please follow the instructions here to add
                            collaborating companies to your project workspace.
                          </Link>
                        </div>
                      }
                      interactive
                    >
                      <InfoOutlined fontSize="small" />
                    </Tooltip>
                  </Grid>

                  <table
                    style={{ emptyCells: 'show' }}
                    data-testid="collaborating-companies-tableview"
                  >
                    <tr style={{ textAlign: 'left' }}>
                      <th>Company Name</th>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <th>Start Date</th>
                      <td>&nbsp;</td>

                      <th>End Date</th>
                    </tr>
                    {participantCompanies.map((res: any) => {
                      return (
                        <>
                          <tr style={{ textAlign: 'left' }}>
                            <p>{res.name}</p>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td style={{ fontSize: '90%' }}>
                              {res?.started_on
                                ? `${new Date(
                                    res?.started_on,
                                  ).toLocaleDateString()}`
                                : null}
                            </td>
                            {res?.ended_on ? (
                              <td>- &nbsp; </td>
                            ) : (
                              <td> &nbsp; </td>
                            )}
                            <td style={{ fontSize: '90%' }}>
                              {res?.ended_on
                                ? `${new Date(
                                    res?.ended_on,
                                  ).toLocaleDateString()}`
                                : null}
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </table>
                </>
              </Grid>
              <Grid item xs={4} />
              <Grid item xs={2} className={classes.editBtn}>
                {isAdmin || isProjectOwner(Number(projectId)) ? (
                  <Button
                    data-testid="button-edit-project-details"
                    variant="contained"
                    onClick={() => setOnEdit(!onEdit)}
                  >
                    EDIT
                  </Button>
                ) : null}
              </Grid>
            </Grid>
          ) : (
            <div>
              <Formik
                initialValues={{
                  ...initialValues,
                  management_type: data?.response?.data?.management_type || '',
                  project_description: data?.response?.data?.description || '',
                }}
                onSubmit={onSubmit}
                enableReinitialize
              >
                {formik => {
                  initialValues.project_description = data?.response?.data
                    ?.description as any;
                  initialValues.management_type =
                    data?.response?.data?.management_type;
                  initialValues.IP_Owner_Companies =
                    data?.response?.data?.ip_owner_companies;
                  initialValues.Collaborating_Companies =
                    data?.response?.data?.participant_companies;

                  return (
                    <>
                      <h4>{data?.response?.data?.name}</h4>
                      <InputLabel>Description</InputLabel>
                      <TextField
                        name="project_description"
                        type="text"
                        placeholder={data?.response?.data?.description}
                        onChange={formik.handleChange}
                        value={formik.values.project_description}
                        rows={3}
                        multiline
                        inputProps={{
                          maxLength: 200,
                        }}
                        style={{ width: '50%' }}
                        data-testid="textfield-project-description"
                      />
                      <FormHelperText>
                        Give a brief description of your project (max 200
                        characters)
                      </FormHelperText>
                      <InputLabel style={{ paddingTop: '1%' }}>
                        Management Type*
                      </InputLabel>
                      <TextField
                        name="management_type"
                        select
                        onChange={formik.handleChange}
                        value={formik.values.management_type}
                        fullWidth
                        required
                        style={{ width: '50%' }}
                        onClick={() => setManagementType(false)}
                        error={managementType}
                        helperText={
                          managementType ? 'Provide the Management Type' : ''
                        }
                      >
                        <MenuItem value="" disabled>
                          Select
                        </MenuItem>
                        {formik.values.management_type ===
                        PROJECT_MGMT_NO_MGMT ? (
                          <MenuItem value={PROJECT_MGMT_NO_MGMT}>
                            EnTec managed
                          </MenuItem>
                        ) : (
                          ''
                        )}
                        <MenuItem value={PROJECT_MGMT_TYPE_OWNERS}>
                          Project Owners
                        </MenuItem>
                        <MenuItem value={PROJECT_MGMT_TYPE_OWNERS_END_USERS}>
                          Project Owners and End Users
                        </MenuItem>
                      </TextField>
                      <FormHelperText>
                        Select Management Type for the Project
                      </FormHelperText>
                      <FormHelperText>
                        <Link
                          className={classes.hyperlink}
                          to={getTechDocsLink('project-access')}
                          target="_blank"
                        >
                          Click here
                        </Link>{' '}
                        to know how management type effects your project
                        workspace.
                      </FormHelperText>

                      <Grid container spacing={2} style={{ paddingTop: '3%' }}>
                        <Grid item>
                          <Button
                            id="btn-cancel-edit-project"
                            variant="outlined"
                            onClick={() => {
                              setOnEdit(false);
                            }}
                          >
                            Cancel
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            id="btn-submit-edit-project"
                            variant="contained"
                            type="submit"
                            onClick={() => onCheckForm(formik)}
                            data-testid="button-save"
                          >
                            Save
                          </Button>
                        </Grid>
                      </Grid>
                    </>
                  );
                }}
              </Formik>
            </div>
          )}
        </div>
      ),
    },
  ];

  const isSelfServicePresent =
    projectData?.response?.data?.management_type ===
    PROJECT_MGMT_TYPE_OWNERS_END_USERS;

  if (isSelfServicePresent) {
    projectDetailsTabs.push({
      label: 'SELF SERVICE ACCESS',
      value: 'self_service_access',
      content: (
        <div>
          {!onEditSelfService ? (
            <Grid container>
              <Grid item xs={6}>
                <>
                  <h4>Self Service Access</h4>
                  <p style={{ fontSize: '90%' }}>
                    {selfService ? 'Enabled' : 'Disabled'}
                  </p>
                  {!selfService && (
                    <>
                      <h4 style={{ paddingTop: '4%' }}>Access Instructions</h4>
                      <p style={{ fontSize: '90%' }}>
                        {selfServiceData?.instructions}
                      </p>
                      <h4 style={{ paddingTop: '4%' }}>Link</h4>
                      <a
                        rel="external"
                        href={`//${selfServiceData?.url_link}`}
                        target="_blank"
                        className={classes.link}
                      >
                        <span style={{ display: 'flex' }}>
                          {selfServiceData?.url_link}
                        </span>
                      </a>
                    </>
                  )}
                </>
              </Grid>
              <Grid item xs={4} />
              <Grid item xs={2} className={classes.editBtn}>
                {isAdmin || isProjectOwner(Number(projectId)) ? (
                  <Button
                    variant="contained"
                    onClick={() => handleEditSelfService()}
                  >
                    EDIT
                  </Button>
                ) : null}
              </Grid>
            </Grid>
          ) : (
            <div className={classes.selfServiceDiv}>
              <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                enableReinitialize
              >
                {formik => {
                  initialValues.link = selfServiceData?.url_link;
                  initialValues.access_instructions =
                    selfServiceData?.instructions || '';
                  return (
                    <>
                      <InputLabel style={{ paddingTop: '1%' }}>
                        Self Service Access
                      </InputLabel>
                      <FormHelperText>
                        Allow users to request access to the project by enabling
                        this feature. If you choose to disable this feature;
                        provide access instructions to users.
                      </FormHelperText>
                      <Switch
                        checked={selfServiceEditSwitch}
                        onChange={handleChange}
                        color="primary"
                        name="checkedB"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                        className={classes.inputLabel}
                      />
                      {selfServiceEditSwitch ? (
                        ''
                      ) : (
                        <>
                          <InputLabel className={classes.inputLabel}>
                            Access Instructions *
                          </InputLabel>
                          <TextField
                            name="access_instructions"
                            type="text"
                            placeholder=""
                            onChange={formik.handleChange}
                            value={formik.values.access_instructions}
                            error={
                              formik.values.access_instructions?.trim()
                                .length === 0
                            }
                            required
                            rows={3}
                            multiline
                            inputProps={{
                              maxLength: 200,
                            }}
                            style={{ width: '100%' }}
                          />
                          <FormHelperText className={classes.ownerLable}>
                            Provide instructions on how to request access to
                            this project.
                          </FormHelperText>
                          <InputLabel className={classes.inputLabel}>
                            Link *
                          </InputLabel>
                          <TextField
                            name="link"
                            type="text"
                            placeholder="https://www.example.com"
                            required
                            error={
                              formik.values.link === '' ||
                              !isValidUrl(formik.values.link)
                            }
                            onChange={formik.handleChange}
                            value={formik.values.link}
                            rows={3}
                            style={{ width: '100%' }}
                          />
                          <FormHelperText className={classes.ownerLable}>
                            {`Provide a link to request access to the project. ${URL_CUE}`}
                          </FormHelperText>
                        </>
                      )}
                      <Grid container spacing={2} style={{ paddingTop: '3%' }}>
                        <Grid item>
                          <Button
                            id="btn-cancel-edit-project"
                            variant="outlined"
                            onClick={handleCancelEditHandler}
                          >
                            Cancel
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            id="btn-submit-edit-project"
                            variant="contained"
                            type="submit"
                            onClick={() => handleSaveSelfServiceForm(formik)}
                            disabled={
                              !selfServiceEditSwitch &&
                              (formik.values.link === '' ||
                                formik.values.access_instructions?.trim()
                                  .length === 0 ||
                                !isValidUrl(formik.values.link))
                            }
                          >
                            Save
                          </Button>
                        </Grid>
                      </Grid>
                    </>
                  );
                }}
              </Formik>
            </div>
          )}
        </div>
      ),
    });
  }

  projectDetailsTabs.push({
    label: 'ROLE MANAGEMENT',
    value: 'role_management',
    content: (
      <ProjectRoleManagement
        projectId={projectId}
        projectData={projectData?.response?.data}
        loading={loading}
        fetchContent={() => {
          fetchContent();
          setActiveTab(isSelfServicePresent ? 2 : 1);
        }}
      />
    ),
  });

  return !onCreate ? (
    <PageLayout
      type="entity"
      title={data?.response?.data?.name || null}
      headerAdditionalControls={
        <ProjectOwners owners={projectData?.response?.data?.owners || null} />
      }
      backToLink={backToLink}
    >
      <div className={classes.container}>
        <Tabs tabs={projectDetailsTabs} initialActiveTabIndex={activeTab} />
      </div>
      <Dialog
        open={open}
        onClose={handleDialogClose}
        maxWidth="xs"
        style={{ top: '5' }}
        className={classes.dialog}
      >
        <DialogContent
          id="project-update-confirmed-dialog"
          style={{ fontWeight: 'bold' }}
        >
          Your project is being edited, please wait whilst we add it to your
          project workspace.
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
          <Button
            id="dialog-close-button"
            variant="contained"
            size="small"
            onClick={handleDialogClose}
            style={{ backgroundColor: 'black' }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </PageLayout>
  ) : (
    <PageLayout
      type="entity"
      title="Create New Project"
      backToLink={backToLink}
    >
      <Dialog
        open={openSuccessCreate}
        onClose={handleCreationDialogClose}
        maxWidth="xs"
        style={{ top: '5' }}
        className={classes.dialog}
      >
        <DialogContent style={{ fontWeight: 'bold' }}>
          Your project creation request has been submitted, please wait while we
          add it to your project workspace.
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
          <Button
            variant="contained"
            size="small"
            onClick={handleCreationDialogClose}
            style={{ backgroundColor: 'black' }}
            // href={`/projects/${projectId}`}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <div className={classes.container} style={{ marginTop: '1%' }}>
        <Formik initialValues={createInitialValues} onSubmit={createProject}>
          {formik => (
            <Form>
              <FormControl error={projectNameError} fullWidth>
                <InputLabel>Project Name *</InputLabel>
                <TextField
                  name="project_name"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.project_name}
                  onClick={() => setProjectNameError(false)}
                  style={{ width: '50%' }}
                  error={projectNameError}
                />
                <FormHelperText>
                  Provide a unique name for your project workspace
                </FormHelperText>
              </FormControl>
              <FormControl error={projectKeyError} fullWidth>
                <InputLabel style={{ paddingTop: '1%' }}>
                  Project Key *
                </InputLabel>
                <TextField
                  name="project_key"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.project_key}
                  style={{ width: '50%' }}
                  onClick={() => setProjectKeyError(false)}
                  error={projectKeyError}
                  inputProps={{
                    maxLength: 8,
                  }}
                />
                <FormHelperText>Provide a Project Key</FormHelperText>
              </FormControl>
              <InputLabel style={{ paddingTop: '1%' }}>Description</InputLabel>
              <TextField
                name="project_description"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.project_description}
                rows={3}
                style={{ width: '50%' }}
                multiline
                inputProps={{
                  maxLength: 200,
                }}
              />
              <FormHelperText>
                Give a brief description of your project (max 200 characters)
              </FormHelperText>
              <FormControl error={managementTypeError} fullWidth>
                <InputLabel
                  style={{ paddingTop: '1%' }}
                  htmlFor="management_type"
                >
                  Management Type*
                </InputLabel>
                <Select
                  id="management_type"
                  name="management_type"
                  input={<OutlinedInput label="Tag" />}
                  displayEmpty
                  onChange={formik.handleChange}
                  onClick={() => setManagementTypeError(false)}
                  value={formik.values.management_type}
                  style={{ width: '50%' }}
                  error={managementTypeError}
                >
                  <MenuItem value="" disabled>
                    Select
                  </MenuItem>
                  <MenuItem value={PROJECT_MGMT_NO_MGMT}>
                    No Management
                  </MenuItem>
                  <MenuItem value={PROJECT_MGMT_TYPE_OWNERS}>
                    Project Owners
                  </MenuItem>
                  <MenuItem value={PROJECT_MGMT_TYPE_OWNERS_END_USERS}>
                    Project Owners and End Users
                  </MenuItem>
                </Select>
                <FormHelperText>
                  Select Management type for the project
                </FormHelperText>
              </FormControl>
              <FormControl error={ownersListError} fullWidth>
                <InputLabel style={{ paddingTop: '1%' }}>
                  Project Owners*
                </InputLabel>
                <Field
                  style={{ width: '50%' }}
                  name="project_owners"
                  className={classes.lowercaseChip}
                  component={ChipInput}
                  variant="outlined"
                  color="success"
                  onClick={() => setOwnersListError(false)}
                  error={ownersListError}
                />
                {ownersListError ? (
                  <FormHelperText>Provide the Project Owners</FormHelperText>
                ) : null}
              </FormControl>
              <FormHelperText className={classes.ownerLable}>
                Press enter or space key to add email id(s) to the list. Press
                ‘Save’ to save the changes.
              </FormHelperText>
              <FormHelperText className={classes.ownerLable}>
                The first person added will be considered "primary" person.
              </FormHelperText>
              <FieldArray name="IP_Owner_Companies">
                {FieldArrayProps => {
                  const { push, remove, form } = FieldArrayProps;
                  const { values } = form;
                  const { IP_Owner_Companies } = values;
                  return (
                    <>
                      <Grid container style={{ paddingTop: '1%' }}>
                        <Grid item xs={2}>
                          <InputLabel style={{ paddingTop: '1%' }}>
                            IP Owner Companies*
                          </InputLabel>
                          <FormHelperText
                            style={{ paddingBottom: '2%', width: 218 }}
                          >
                            Company names cannot be identical
                          </FormHelperText>
                        </Grid>
                        <Grid item xs={2} style={{ paddingLeft: '6.6%' }}>
                          <InputLabel
                            style={{
                              paddingTop: '1%',
                            }}
                          >
                            Start Date*
                          </InputLabel>
                        </Grid>
                        <Grid item xs={2} style={{ paddingLeft: '4.8%' }}>
                          <InputLabel style={{ paddingTop: '1%' }}>
                            End Date
                          </InputLabel>
                        </Grid>
                        <Grid item xs={2} style={{ paddingTop: '1%' }}>
                          <InputLabel style={{ paddingLeft: '13.9%' }}>
                            Percentage*
                          </InputLabel>
                          <FormHelperText
                            style={{
                              paddingBottom: '2%',
                              paddingLeft: '13.9%',
                            }}
                          >
                            Total must equal 100
                          </FormHelperText>
                        </Grid>
                      </Grid>
                      {IP_Owner_Companies?.map((company: any, index: any) => (
                        <div key={company}>
                          <div
                            style={{
                              display: 'inline',
                              flexDirection: 'row',
                            }}
                          >
                            <Grid style={{ paddingBottom: '1%' }}>
                              <FormControl
                                error={
                                  createIPError &&
                                  ownersListError &&
                                  ownersListEValue[index]
                                }
                                style={{ width: '22%' }}
                              >
                                <Select
                                  name={`IP_Owner_Companies[${index}].company_id`}
                                  onClick={() => {
                                    setCreateIPError(false);
                                    setOwnersListEValue([]);
                                  }}
                                  input={<OutlinedInput label="Tag" />}
                                  onChange={(value: any) => {
                                    formik.setFieldValue(
                                      `IP_Owner_Companies[${index}].company_id`,
                                      value.target.value,
                                    );
                                    setCreateIPError(false);
                                    setOwnersListEValue([]);
                                  }}
                                  value={IP_Owner_Companies[index].company_id}
                                  displayEmpty
                                >
                                  {/* {menuItems.map((res: any) => ( */}
                                  <MenuItem value="" disabled>
                                    Select
                                  </MenuItem>
                                  {participantCompanies.map((res: any) => (
                                    <MenuItem value={res.id}>
                                      {res.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                                {ownersListError && ownersListEValue[index] ? (
                                  <FormHelperText>
                                    {ownersListEValue[index]}
                                  </FormHelperText>
                                ) : null}
                              </FormControl>
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                  style={{
                                    paddingLeft: '1%',
                                    width: '15%',
                                  }}
                                  disableToolbar
                                  variant="inline"
                                  format="MM/dd/yyyy"
                                  id={`IP_Owner_Companies[${index}].started_on`}
                                  name={`IP_Owner_Companies[${index}].started_on`}
                                  key={`IP_Owner_Companies[${index}].started_on`}
                                  value={
                                    IP_Owner_Companies[index].started_on
                                      ? IP_Owner_Companies[index].started_on
                                      : null
                                  }
                                  error={
                                    IP_Owner_Companies[index].started_on
                                      ? false
                                      : true
                                  }
                                  // onClick={() => setIPStartDateError(false)}
                                  helperText={
                                    IP_Owner_Companies[index].started_on
                                      ? null
                                      : 'Start Date is required'
                                  }
                                  onChange={(value: any) => {
                                    formik.setFieldValue(
                                      `IP_Owner_Companies[${index}].started_on`,
                                      value,
                                    );
                                  }}
                                  KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                  }}
                                  InputProps={{ readOnly: true }}
                                />
                                <KeyboardDatePicker
                                  style={{
                                    paddingLeft: '1%',
                                    width: '15%',
                                  }}
                                  disableToolbar
                                  variant="inline"
                                  format="MM/dd/yyyy"
                                  id={`IP_Owner_Companies[${index}].ended_on`}
                                  name={`IP_Owner_Companies[${index}].ended_on`}
                                  minDate={IP_Owner_Companies[index].started_on}
                                  value={
                                    IP_Owner_Companies[index].ended_on
                                      ? IP_Owner_Companies[index].ended_on
                                      : null
                                  }
                                  onChange={(value: any) => {
                                    formik.setFieldValue(
                                      `IP_Owner_Companies[${index}.ended_on]`,
                                      value,
                                    );
                                  }}
                                  KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                  }}
                                  InputProps={{ readOnly: true }}
                                />
                              </MuiPickersUtilsProvider>
                              <FormControl
                                error={createHundredCheck}
                                style={{
                                  width: '20%',
                                  paddingLeft: '1%',
                                }}
                              >
                                <TextField
                                  name={`IP_Owner_Companies[${index}].percentage`}
                                  key={`IP_Owner_Companies[${index}].percentage`}
                                  type="number"
                                  onClick={() => setCreateHundredCheck(false)}
                                  onChange={(value: any) =>
                                    formik.setFieldValue(
                                      `IP_Owner_Companies[${index}].percentage`,
                                      parseInt(value.target.value, 10),
                                    )
                                  }
                                  value={IP_Owner_Companies[index].percentage}
                                  fullWidth
                                />
                                {createHundredCheck ? (
                                  <FormHelperText>
                                    Total percentage must equal 100
                                  </FormHelperText>
                                ) : null}
                              </FormControl>
                              <Button
                                type="button"
                                onClick={() => {
                                  if (IP_Owner_Companies.length > 1) {
                                    remove(index);
                                    setCreateIPError(false);
                                    setOwnersListEValue([]);
                                  }
                                }}
                              >
                                <DeleteIcon color="primary" />
                              </Button>
                            </Grid>
                          </div>
                        </div>
                      ))}{' '}
                      <div>
                        <Button
                          type="button"
                          variant="outlined"
                          onClick={() =>
                            push({ company_id: '', started_on: new Date() })
                          }
                        >
                          Add &nbsp;
                          <AddCircle />
                        </Button>
                      </div>
                      {ipOwnerError ? (
                        <FormHelperText
                          style={{ paddingTop: '1%', color: '#CC0000' }}
                        >
                          Please add atleast one IP Owner Company
                        </FormHelperText>
                      ) : (
                        ''
                      )}
                      <FormHelperText style={{ paddingTop: '1%' }}>
                        If you select a company other than your own, contacting
                        process may be required.
                      </FormHelperText>
                      <FormHelperText>
                        Please only select collaborators you require at the
                        moment.
                      </FormHelperText>
                    </>
                  );
                }}
              </FieldArray>
              <FieldArray name="Collaborating_Companies">
                {FieldArrayProps => {
                  const { push, remove, form } = FieldArrayProps;
                  const { values } = form;
                  const { Collaborating_Companies } = values;
                  return (
                    <>
                      <Grid container style={{ paddingTop: '1%' }}>
                        <Grid item xs={2}>
                          <InputLabel style={{ paddingTop: '1%' }}>
                            Collaborating Companies*
                          </InputLabel>
                          <FormHelperText
                            style={{ paddingBottom: '2%', width: 218 }}
                          >
                            Company names cannot be identical
                          </FormHelperText>
                        </Grid>
                        <Grid item xs={2} style={{ paddingLeft: '6.6%' }}>
                          <InputLabel
                            style={{
                              paddingTop: '1%',
                            }}
                          >
                            Start Date*
                          </InputLabel>
                        </Grid>
                        <Grid item xs={2} style={{ paddingLeft: '4.8%' }}>
                          <InputLabel style={{ paddingTop: '1%' }}>
                            End Date
                          </InputLabel>
                        </Grid>
                      </Grid>
                      {Collaborating_Companies?.map(
                        (company: any, index: any) => (
                          <div key={company}>
                            <div
                              style={{
                                display: 'inline',
                                flexDirection: 'row',
                              }}
                            >
                              <Grid style={{ paddingBottom: '1%' }}>
                                <FormControl
                                  error={
                                    createCCError && createCCErrorList[index]
                                  }
                                  style={{ width: '22%' }}
                                >
                                  <Select
                                    name={`Collaborating_Companies[${index}].company_id`}
                                    key={`Collaborating_Companies[${index}].company_id`}
                                    onClick={() => {
                                      setCreateCCError(false);
                                      setCreateCCErrorList([]);
                                    }}
                                    input={<OutlinedInput label="Tag" />}
                                    onChange={(value: any) => {
                                      formik.setFieldValue(
                                        `Collaborating_Companies[${index}].company_id`,
                                        value.target.value,
                                      );
                                      setCreateCCError(false);
                                      setCreateCCErrorList([]);
                                    }}
                                    value={
                                      Collaborating_Companies[index]?.company_id
                                    }
                                    displayEmpty
                                  >
                                    <MenuItem value="" disabled>
                                      Select
                                    </MenuItem>
                                    {participantCompanies.map((res: any) => (
                                      <MenuItem value={res.id}>
                                        {res.name}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                  {createCCError && createCCErrorList[index] ? (
                                    <FormHelperText>
                                      {createCCErrorList[index]}
                                    </FormHelperText>
                                  ) : null}
                                </FormControl>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <KeyboardDatePicker
                                    style={{
                                      paddingLeft: '1%',
                                      width: '15%',
                                    }}
                                    disableToolbar
                                    variant="inline"
                                    format="MM/dd/yyyy"
                                    id={`Collaborating_Companies[${index}].started_on`}
                                    name={`Collaborating_Companies[${index}].started_on`}
                                    key={`Collaborating_Companies[${index}].started_on`}
                                    error={
                                      Collaborating_Companies[index].started_on
                                        ? false
                                        : true
                                    }
                                    helperText={
                                      Collaborating_Companies[index].started_on
                                        ? null
                                        : 'Start Date is required'
                                    }
                                    value={
                                      Collaborating_Companies[index].started_on
                                        ? Collaborating_Companies[index]
                                            .started_on
                                        : null
                                    }
                                    onChange={(value: any) => {
                                      formik.setFieldValue(
                                        `Collaborating_Companies[${index}].started_on`,
                                        value,
                                      );
                                    }}
                                    KeyboardButtonProps={{
                                      'aria-label': 'change date',
                                    }}
                                    InputProps={{ readOnly: true }}
                                  />
                                  <KeyboardDatePicker
                                    style={{
                                      paddingLeft: '1%',
                                      width: '15%',
                                    }}
                                    disableToolbar
                                    variant="inline"
                                    format="MM/dd/yyyy"
                                    id={`Collaborating_Companies[${index}].ended_on`}
                                    name={`Collaborating_Companies[${index}].ended_on`}
                                    key={`Collaborating_Companies[${index}].ended_on`}
                                    minDate={
                                      Collaborating_Companies[index].started_on
                                    }
                                    value={
                                      Collaborating_Companies[index].ended_on
                                        ? Collaborating_Companies[index]
                                            .ended_on
                                        : null
                                    }
                                    onChange={(value: any) => {
                                      formik.setFieldValue(
                                        `Collaborating_Companies[${index}].ended_on`,
                                        value,
                                      );
                                    }}
                                    KeyboardButtonProps={{
                                      'aria-label': 'change date',
                                    }}
                                    InputProps={{ readOnly: true }}
                                  />
                                </MuiPickersUtilsProvider>

                                <Button
                                  type="button"
                                  onClick={() => {
                                    if (Collaborating_Companies.length > 1) {
                                      remove(index);
                                      setCreateCCError(false);
                                      setCreateCCErrorList([]);
                                    }
                                  }}
                                >
                                  <DeleteIcon color="primary" />
                                </Button>
                              </Grid>
                            </div>
                          </div>
                        ),
                      )}{' '}
                      <div>
                        <Button
                          type="button"
                          variant="outlined"
                          onClick={() =>
                            push({ company_id: '', started_on: new Date() })
                          }
                        >
                          Add &nbsp;
                          <AddCircle />
                        </Button>
                      </div>
                      {collaboratingError ? (
                        <FormHelperText
                          style={{ paddingTop: '1%', color: '#CC0000' }}
                        >
                          Please add atleast one Collaborating Company
                        </FormHelperText>
                      ) : (
                        ''
                      )}
                      <FormHelperText style={{ paddingTop: '1%' }}>
                        If you select a company other than your own, contacting
                        process may be required.
                      </FormHelperText>
                      <FormHelperText>
                        Please only select collaborators you require at the
                        moment.
                      </FormHelperText>
                      <Grid container spacing={2} style={{ paddingTop: '2%' }}>
                        <Grid item>
                          <Button
                            variant="outlined"
                            onClick={() => setOnEdit(false)}
                          >
                            Cancel
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            variant="contained"
                            type="button"
                            disabled={createButtonStatus}
                          >
                            SAVE & CREATE
                          </Button>
                        </Grid>
                      </Grid>
                    </>
                  );
                }}
              </FieldArray>
            </Form>
          )}
        </Formik>
      </div>
    </PageLayout>
  );
};

export const ViewEditProject = () => {
  const { projectId } = useParams() as { projectId: string };
  return <ViewEditProjectComponent projectId={projectId} />;
};
