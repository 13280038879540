import { Table, TableColumn } from '@backstage/core-components';
import {
  errorApiRef,
  microsoftAuthApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useAsyncFn } from 'react-use';
import { EmptyDataMessage } from '@internal/sg-ui-kit';
import { CreatorRolesData, projectApiRef } from '../../api';
import { Progress } from '@backstage/core-components';
import {
  ARTIFACTORY_DEV_TOOL_ID,
  DevToolData,
  EMAIL_REGEX,
  GITHUB_DEV_TOOL_ID,
  INVALID_EMAIL_ERROR,
  STOP_GITHUB_AND_SELF_HOSTED_ATLASSIAN,
} from 'usg-types';
import { transformResourceName } from 'sg-utils-frontend';
import { isResponseStatus2XX } from '@internal/sg-utils-common';
import { Field, Formik } from 'formik';
import { AntSwitch, SGEmailChipInput } from '@internal/sg-ui-kit';
import { ProjectDetailOwners } from './ProjectDetailOwners';
import { Devtoolhook, useDevtoolsApi } from '../../hooks/useDevtoolsApi';
import { AlertModal } from '../AlertModal';
import { useNavigate } from 'react-router';
import { featureFlagsApiRef } from '@internal/plugin-feature-flags';
import { usePermissions } from '../../hooks/usePermissions';
import { useStyles } from './styles';

interface ProjectRoleManagementProps {
  projectId: string;
  loading: boolean;
  projectData: any;
  fetchContent: () => void;
}

const DEFAULT_EDIT_ROLES_DATA = {
  project_resource_creators: {},
  project_user_group_creators: {},
};
const REMOVE = 'remove';
const ADD = 'add';
const NONE = 'None';

export const ProjectRoleManagement = ({
  projectId,
  loading,
  projectData,
  fetchContent,
}: ProjectRoleManagementProps) => {
  const classes = useStyles();
  const authref = useApi(microsoftAuthApiRef);
  const projectApi = useApi(projectApiRef);
  const errorApi = useApi(errorApiRef);
  const featureFlagsApi = useApi(featureFlagsApiRef);
  const navigate = useNavigate();
  const [editMemberRoles, setEditMemberRoles] = useState<boolean>(false);
  const [editMemberRolesData, setEditMemberRolesData] = useState<Array<any>>(
    [],
  );
  const [toBeSavedRolesData, setToBeSavedRolesData] = useState<any>(
    DEFAULT_EDIT_ROLES_DATA,
  );
  const [creatorRoleReponse, setCreatorRoleReponse] = useState<any>();
  const [userRolesData, setUserRolesData] = useState([]);
  const [userEmailChips, setUserEmailChips] = useState([]);
  const [isEditOwnersMode, setIsEditOwnersMode] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isAddUserEnable, setIsAddUserEnable] = useState(true);
  const [isAddUserLoading, setIsAddUserLoading] = useState(false);
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [resourcesList, setResourcesList] = useState<Array<DevToolData>>([]);
  const {
    allDevtools,
    allDevtoolsIdToNameMapping,
    allDevtoolsNametoIdMapping,
    isSuccess: allDevtoolsSuccess,
    message: alldevtoolsMessage,
    isLoading: isDevtoolsLoading,
  }: Devtoolhook = useDevtoolsApi();
  const {
    isAdmin,
    isProjectOwner,
    isLoading: isRolesLoading,
  } = usePermissions();

  const handleGroupRoleChange = (email: string, creatorGroupRole: boolean) => {
    const toBeupdatedData = structuredClone(toBeSavedRolesData);
    const toBeupdatedEditData = structuredClone(editMemberRolesData);
    if (creatorGroupRole) {
      // if previously selected group role, then deselect it and put in to be removed group role for user

      toBeupdatedData.project_user_group_creators[REMOVE] ??= [];
      toBeupdatedData.project_user_group_creators[REMOVE].push(email);

      toBeupdatedData.project_user_group_creators[ADD] ??= [];

      const emailInAdd =
        toBeupdatedData.project_user_group_creators[ADD].indexOf(email);
      if (emailInAdd > -1)
        toBeupdatedData.project_user_group_creators[ADD].splice(emailInAdd, 1);
    } else {
      // if previously not selected group role, then select it and put in to be removed group role for user
      toBeupdatedData.project_user_group_creators[ADD] ??= [];
      toBeupdatedData.project_user_group_creators[ADD].push(email);

      toBeupdatedData.project_user_group_creators[REMOVE] ??= [];

      const emailInRemove =
        toBeupdatedData.project_user_group_creators[REMOVE].indexOf(email);
      if (emailInRemove > -1)
        toBeupdatedData.project_user_group_creators[REMOVE].splice(
          emailInRemove,
          1,
        );
    }
    const targetObj = toBeupdatedEditData.find(
      (item: any) => item.email === email,
    );
    if (targetObj) {
      targetObj.user_group_role = !creatorGroupRole;
    }
    setEditMemberRolesData(toBeupdatedEditData);
    setToBeSavedRolesData(toBeupdatedData);
  };

  const removeAllGroupRoles = (
    groupCreatorRole: boolean,
    email: string,
    toBeupdatedData: any,
  ) => {
    if (groupCreatorRole) {
      toBeupdatedData.project_user_group_creators[REMOVE] ??= [];
      toBeupdatedData.project_user_group_creators[REMOVE].push(email);
      toBeupdatedData.project_user_group_creators[ADD] ??= [];

      const emailInAdd =
        toBeupdatedData.project_user_group_creators[ADD].indexOf(email);
      if (emailInAdd > -1)
        toBeupdatedData.project_user_group_creators[ADD].splice(emailInAdd, 1);
    }
    // currentRoles.splice(0, currentRoles.length);
  };

  const handleResourceRoleChange = (
    resourceName: any,
    currentResourceRoles: any,
    email: string,
    isPreviousChecked: boolean,
  ) => {
    const toBeupdatedData = structuredClone(toBeSavedRolesData);
    if (isPreviousChecked) {
      // if previously selected resource role, then deselect it and put in to be removed resource role for user
      currentResourceRoles.splice(
        currentResourceRoles.indexOf(resourceName),
        1,
      );

      toBeupdatedData.project_resource_creators[REMOVE] ??= {};
      toBeupdatedData.project_resource_creators[REMOVE][resourceName] ??= [];
      toBeupdatedData.project_resource_creators[REMOVE][resourceName].push(
        email,
      );

      toBeupdatedData.project_resource_creators[ADD] ??= {};

      if (toBeupdatedData.project_resource_creators[ADD][resourceName]) {
        const emailInAdd =
          toBeupdatedData.project_resource_creators[ADD][resourceName].indexOf(
            email,
          );
        if (emailInAdd > -1)
          toBeupdatedData.project_resource_creators[ADD][resourceName].splice(
            emailInAdd,
            1,
          );
      }
    } else {
      // if previously not selected resource role, then select it and put in to be added resource role for user
      currentResourceRoles.push(resourceName);

      toBeupdatedData.project_resource_creators[ADD] ??= {};
      toBeupdatedData.project_resource_creators[ADD][resourceName] ??= [];
      toBeupdatedData.project_resource_creators[ADD][resourceName].push(email);

      toBeupdatedData.project_resource_creators[REMOVE] ??= {};

      if (toBeupdatedData.project_resource_creators[REMOVE][resourceName]) {
        const emailInAdd =
          toBeupdatedData.project_resource_creators[REMOVE][
            resourceName
          ].indexOf(email);
        if (emailInAdd > -1)
          toBeupdatedData.project_resource_creators[REMOVE][
            resourceName
          ].splice(emailInAdd, 1);
      }
    }
    setToBeSavedRolesData(toBeupdatedData);
  };

  const removeAllResourceRoles = (
    currentResourceRoles: any,
    email: string,
    toBeupdatedData: any,
  ) => {
    toBeupdatedData.project_resource_creators[ADD] ??= {};
    toBeupdatedData.project_resource_creators[REMOVE] ??= {};

    // if none is selected then discard every selected role resource from to be added and put in to be added
    if (currentResourceRoles.length > 0) {
      currentResourceRoles.forEach((resourceName: string) => {
        toBeupdatedData.project_resource_creators[REMOVE][resourceName] ??= [];
        toBeupdatedData.project_resource_creators[REMOVE][resourceName].push(
          email,
        );

        if (toBeupdatedData.project_resource_creators?.[ADD]?.[resourceName]) {
          const emailInAdd =
            toBeupdatedData.project_resource_creators[ADD][
              resourceName
            ].indexOf(email);
          if (emailInAdd > -1) {
            toBeupdatedData.project_resource_creators[ADD][resourceName].splice(
              emailInAdd,
              1,
            );
          }
        }
      });
    }
    currentResourceRoles.splice(0, currentResourceRoles.length);
  };

  const handleClearResourceRoleChange = (
    currentResourceRoles: any,
    email: string,
    isNoneSelected: boolean,
  ) => {
    // if None is already selected nothing to be done
    if (isNoneSelected) return;
    const toBeupdatedData = structuredClone(toBeSavedRolesData);
    removeAllResourceRoles(currentResourceRoles, email, toBeupdatedData);
    setToBeSavedRolesData(toBeupdatedData);
  };

  const mapUsersRoleDataToTableData = useCallback(() => {
    if (creatorRoleReponse && allDevtools.length !== 0) {
      // user email mapping to group roles and resource roles
      const userEmailRoleMapping: any = {};

      creatorRoleReponse?.project_user_group_creators.forEach((item: any) => {
        userEmailRoleMapping[item.user_email] ??= {};
        userEmailRoleMapping[item.user_email].email ??= item.user_email;
        userEmailRoleMapping[item.user_email].user_group_role = true;
        userEmailRoleMapping[item.user_email].resource_roles ??= [];
      });

      creatorRoleReponse?.project_resource_creators.forEach((resource: any) => {
        resource.resource_creators.forEach((item: any) => {
          userEmailRoleMapping[item.user_email] ??= {};
          userEmailRoleMapping[item.user_email].email ??= item.user_email;
          userEmailRoleMapping[item.user_email].user_group_role ??= false;
          if (userEmailRoleMapping[item.user_email].resource_roles)
            userEmailRoleMapping[item.user_email].resource_roles.push(
              allDevtoolsIdToNameMapping[resource.dev_tool_id],
            );
          else
            userEmailRoleMapping[item.user_email].resource_roles = [
              allDevtoolsIdToNameMapping[resource.dev_tool_id],
            ];
        });
      });
      const ascendingEmailRoleMapping = Object.values(
        userEmailRoleMapping,
      ).sort((a: any, b: any) =>
        a?.email?.toLowerCase() > b?.email?.toLowerCase() ? 1 : -1,
      ) as any;
      setUserRolesData(ascendingEmailRoleMapping);
    }
  }, [creatorRoleReponse, allDevtools, allDevtoolsIdToNameMapping]);

  const handleEditRoles = () => {
    setEditMemberRoles(true);
    setEditMemberRolesData(structuredClone(userRolesData));
  };

  const handleCancelEditRoles = () => {
    // reset to be saved data and edit table data if request is cancelled
    setEditMemberRoles(false);
    setEditMemberRolesData([]);
    setToBeSavedRolesData(DEFAULT_EDIT_ROLES_DATA);
    setIsSaveEnabled(false);
  };

  const removeAllRoles = (
    email: string,
    groupCreatorRole: boolean,
    currentResourceRoles: any,
  ) => {
    const toBeupdatedData = structuredClone(toBeSavedRolesData);

    removeAllGroupRoles(groupCreatorRole, email, toBeupdatedData);
    removeAllResourceRoles(currentResourceRoles, email, toBeupdatedData);

    const newEditMemberData = editMemberRolesData.filter(
      (item: any) => item.email !== email,
    );
    setEditMemberRolesData(newEditMemberData);
    setToBeSavedRolesData(toBeupdatedData);
  };

  const [
    { loading: loadingRolesData = false as boolean },
    fetchProjectRolesData,
  ] = useAsyncFn(async (): Promise<any> => {
    try {
      const token = await authref.getIdToken();
      const memberRolesData =
        await projectApi.getAllUsersWithCreatorRoleInProject(projectId, token);

      setCreatorRoleReponse(memberRolesData);
    } catch (error) {
      errorApi.post(
        new Error(
          `Failed to fetch creator roles data for project ${projectId} due to ${error?.message}`,
        ),
      );
    }
  });

  const updateCreatorRoles = async () => {
    // prepare data according to api request
    const resourceAdd: Array<any> = [];
    if (toBeSavedRolesData.project_resource_creators?.add) {
      const devtools = Object.keys(
        toBeSavedRolesData.project_resource_creators?.add,
      );
      if (devtools?.length > 0) {
        devtools.forEach(tool => {
          const dev_tool_id = allDevtoolsNametoIdMapping[tool];
          const users = toBeSavedRolesData.project_resource_creators?.add[tool];
          if (users?.length > 0) {
            const usermapping = users.map((user: any) => {
              return {
                user_email: user,
              };
            });
            resourceAdd.push({
              dev_tool_id: dev_tool_id,
              resource_creators: usermapping,
            });
          }
        });
      }
    }

    const resourceRemove: Array<any> = [];
    if (toBeSavedRolesData.project_resource_creators?.remove) {
      const devtools = Object.keys(
        toBeSavedRolesData.project_resource_creators?.remove,
      );
      if (devtools?.length > 0) {
        devtools.forEach(tool => {
          const dev_tool_id = allDevtoolsNametoIdMapping[tool];
          const users =
            toBeSavedRolesData.project_resource_creators?.remove[tool];
          if (users?.length > 0) {
            const usermapping = users.map((user: any) => {
              return {
                user_email: user,
              };
            });
            resourceRemove.push({
              dev_tool_id: dev_tool_id,
              resource_creators: usermapping,
            });
          }
        });
      }
    }

    const groupAdd: Array<any> =
      toBeSavedRolesData.project_user_group_creators?.add?.map((user: any) => {
        return {
          user_email: user,
        };
      });

    const groupRemove: Array<any> =
      toBeSavedRolesData.project_user_group_creators?.remove?.map(
        (user: any) => {
          return {
            user_email: user,
          };
        },
      );

    const creator_roles_data: CreatorRolesData = {};

    if (resourceAdd?.length) {
      creator_roles_data.project_resource_creators ??= [];
      creator_roles_data.project_resource_creators.push({
        op: ADD,
        value: resourceAdd,
      });
    }

    if (resourceRemove?.length) {
      creator_roles_data.project_resource_creators ??= [];
      creator_roles_data.project_resource_creators.push({
        op: REMOVE,
        value: resourceRemove,
      });
    }

    if (groupAdd?.length) {
      creator_roles_data.project_user_group_creators ??= [];
      creator_roles_data.project_user_group_creators.push({
        op: ADD,
        value: groupAdd,
      });
    }

    if (groupRemove?.length) {
      creator_roles_data.project_user_group_creators ??= [];
      creator_roles_data.project_user_group_creators.push({
        op: REMOVE,
        value: groupRemove,
      });
    }

    try {
      const token = await authref.getIdToken();
      const memberRolesData = await projectApi.updateUsersCreatorRoleInProject(
        projectId,
        token,
        creator_roles_data,
      );
      // reset to to be saved data and edit table data once request is successful
      setToBeSavedRolesData(DEFAULT_EDIT_ROLES_DATA);
      setCreatorRoleReponse(memberRolesData);
      setDialogOpen(true);
    } catch (error) {
      errorApi.post(
        new Error(
          `Failed to update creator roles data for project ${projectId} due to ${error?.message}`,
        ),
      );
      setToBeSavedRolesData(DEFAULT_EDIT_ROLES_DATA);
      setEditMemberRoles(false);
      setEditMemberRolesData([]);
    }
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setEditMemberRoles(false);
    setEditMemberRolesData([]);
    setIsSaveEnabled(false);
  };

  const addUserToTheList = (values: any) => {
    const newEditMemberData = structuredClone(editMemberRolesData);
    const currentUsers = editMemberRolesData.map(user => user.email);
    values.forEach((chip: any) => {
      if (currentUsers.indexOf(chip.value) === -1) {
        newEditMemberData.push({
          email: chip.value,
          user_group_role: false,
          resource_roles: [],
        });
      }
    });
    const ascendingAddMemberData = newEditMemberData?.sort((a: any, b: any) =>
      a?.email?.toLowerCase() > b?.email?.toLowerCase() ? 1 : -1,
    );
    setEditMemberRolesData(ascendingAddMemberData);
    setUserEmailChips([]);
  };

  useEffect(() => {
    (async () => {
      if (!isRolesLoading && (isAdmin || isProjectOwner(Number(projectId)))) {
        await fetchProjectRolesData();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchProjectRolesData, isRolesLoading]);

  useEffect(() => {
    mapUsersRoleDataToTableData();
  }, [mapUsersRoleDataToTableData]);

  useEffect(() => {
    (async () => {
      if (allDevtools?.length !== 0) {
        const isGithubAtlassianStoppedFlag: any =
          await featureFlagsApi.getBinaryFlag(
            STOP_GITHUB_AND_SELF_HOSTED_ATLASSIAN,
          );
        if (isGithubAtlassianStoppedFlag?.data) {
          setResourcesList(
            allDevtools.filter(
              (dev_tool: any) =>
                dev_tool.id !== GITHUB_DEV_TOOL_ID &&
                dev_tool.id !== ARTIFACTORY_DEV_TOOL_ID,
            ),
          );
        } else {
          setResourcesList(allDevtools);
        }
      }
    })();
  }, [allDevtools, featureFlagsApi]);

  if (isDevtoolsLoading || loadingRolesData || isRolesLoading) {
    return <Progress />;
  }

  const columnFields: Array<TableColumn<any>> = [
    {
      field: 'email',
      title: 'Email',
      width: '40%',
      cellStyle: {
        minWidth: '20em',
      },
    },
    {
      title: 'Create User Group',
      width: '15%',
      render: data => {
        const { user_group_role = false, email } = data;
        return !editMemberRoles ? (
          <span>{user_group_role ? 'Enabled' : 'Disabled'}</span>
        ) : (
          <AntSwitch
            checked={user_group_role}
            onChange={() => {
              setIsSaveEnabled(true);
              handleGroupRoleChange(email, user_group_role);
            }}
          />
        );
      },
    },
    {
      title: 'Create Resource Category',
      width: '30%',
      cellStyle: {
        maxWidth: '40em',
      },
      render: data => {
        const { resource_roles = [], email, isSelectOpen = false } = data;
        const isNoneSelected = resource_roles?.length === 0;
        return !editMemberRoles ? (
          <span>
            {resource_roles?.length > 0 ? resource_roles.join(', ') : NONE}
          </span>
        ) : (
          <Select
            id={`resource-roles-${email}`}
            multiple
            value={resource_roles}
            fullWidth
            renderValue={(selected: any) => selected.join(', ')}
            open={isSelectOpen}
            onClose={() => {
              const newEditMemberData = [...editMemberRolesData];
              const index = newEditMemberData.findIndex(
                (item: any) => item.email === email,
              );
              newEditMemberData[index].isSelectOpen = false;
              setEditMemberRolesData(newEditMemberData);
            }}
            onOpen={() => {
              const newEditMemberData = [...editMemberRolesData];
              const index = newEditMemberData.findIndex(
                (item: any) => item.email === email,
              );
              newEditMemberData[index].isSelectOpen = true;
              setEditMemberRolesData(newEditMemberData);
            }}
          >
            <MenuItem
              key={NONE}
              value={NONE}
              onClick={() => {
                setIsSaveEnabled(true);
                handleClearResourceRoleChange(
                  resource_roles,
                  email,
                  isNoneSelected,
                );
              }}
            >
              <Checkbox checked={isNoneSelected} />
              <ListItemText primary={NONE} />
            </MenuItem>
            {resourcesList.map((resource: DevToolData) => {
              const resourceName = transformResourceName(resource.name);
              const checked = resource_roles.indexOf(resourceName) > -1;
              return (
                <MenuItem
                  key={resource.id}
                  value={resourceName}
                  onClick={() => {
                    setIsSaveEnabled(true);
                    handleResourceRoleChange(
                      resourceName,
                      resource_roles,
                      email,
                      checked,
                    );
                  }}
                >
                  <Checkbox
                    checked={resource_roles.indexOf(resourceName) > -1}
                  />
                  <ListItemText primary={resourceName} />
                </MenuItem>
              );
            })}
          </Select>
        );
      },
    },
    {
      field: 'action',
      title: 'Actions',
      width: '15%',
      hidden: !editMemberRoles,
      render: ({ email, user_group_role = false, resource_roles = [] }) => {
        return (
          <Button
            id={`remove-${email}`}
            variant="text"
            color="primary"
            className={classes.actionButton}
            onClick={() => {
              setIsSaveEnabled(true);
              removeAllRoles(email, user_group_role, resource_roles);
            }}
          >
            Remove Roles
          </Button>
        );
      },
    },
  ];

  if (!allDevtoolsSuccess) {
    return (
      <AlertModal
        alertMessage={alldevtoolsMessage}
        isModalOpen={!allDevtoolsSuccess}
        onClose={() => {
          navigate(`/projects/${projectId}`);
        }}
      />
    );
  }

  const checkUserValidity = async (email: string) => {
    try {
      const token = await authref.getIdToken();
      const isValidRes = await projectApi.checkIfUserIsValid(
        projectId,
        email,
        token,
      );
      return isResponseStatus2XX(isValidRes);
    } catch (e) {
      return false;
    }
  };

  const checkAddUserValidity = async (email: string) => {
    const isAddUseValid = await checkUserValidity(email);
    setIsAddUserEnable(isAddUseValid && isAddUserEnable);
    return isAddUseValid;
  };

  // check if all roles are selected properly with required data
  const isRoleSelected = editMemberRolesData?.every((item: any) => {
    return item.resource_roles.length > 0 || item.user_group_role;
  });

  return (
    <>
      <ProjectDetailOwners
        projectId={projectId}
        isEditEnabled={isAdmin || isProjectOwner(Number(projectId))}
        projectData={projectData}
        isEditMode={isEditOwnersMode}
        isRoleEditMode={editMemberRoles}
        updateIsEditMode={(value: boolean) => setIsEditOwnersMode(value)}
        fetchContent={fetchContent}
        checkUserValidity={checkUserValidity}
      />
      {(isAdmin || isProjectOwner(Number(projectId))) && (
        <>
          <hr />
          <Grid
            container
            justifyContent="space-between"
            spacing={0}
            className={classes.header}
          >
            <Typography variant="h2">Project Roles</Typography>
            {!editMemberRoles && !isEditOwnersMode && (
              <Button variant="contained" onClick={handleEditRoles}>
                EDIT
              </Button>
            )}
          </Grid>

          {editMemberRoles && (
            <Grid container alignItems="center" className={classes.search}>
              <Formik
                initialValues={{ userEmails: userEmailChips }}
                onSubmit={() => {}}
                enableReinitialize
              >
                {formik => (
                  <>
                    <Grid item xs={6}>
                      <Field
                        name="userEmails"
                        component={SGEmailChipInput}
                        required
                        variant="outlined"
                        patternMatch={EMAIL_REGEX}
                        helperText="Add emails to assign creator roles for each user groups and resources. Press 'Add User' button to add email id to the list. Press 'Save' to save the changes."
                        errorText={INVALID_EMAIL_ERROR}
                        checkValidity={checkAddUserValidity}
                        handleDeleteCallBack={(isError: boolean) => {
                          setIsAddUserEnable(!isError);
                        }}
                        handleLoadingCallBack={(addUserloading: boolean) => {
                          setIsAddUserLoading(addUserloading);
                        }}
                        handleBeforeAddCallBack={(isError: boolean) => {
                          setIsAddUserEnable(!isError);
                        }}
                        handleBlurInputCallBack={(isError: boolean) => {
                          setIsAddUserEnable(!isError);
                        }}
                      />
                    </Grid>
                    <Button
                      id="add-user"
                      type="submit"
                      variant="contained"
                      onClick={() => addUserToTheList(formik.values.userEmails)}
                      disabled={
                        isAddUserLoading ||
                        !isAddUserEnable ||
                        formik.values.userEmails?.length <= 0
                      }
                    >
                      Add User
                    </Button>
                  </>
                )}
              </Formik>
            </Grid>
          )}

          <Table
            title="Member Roles"
            columns={columnFields}
            data={(editMemberRoles ? editMemberRolesData : userRolesData) || []}
            options={{
              padding: 'dense',
              search: true,
              paging: false,
              draggable: false,
              sorting: false,
              loadingType: 'overlay',
              emptyRowsWhenPaging: false,
              showFirstLastPageButtons: false,
              actionsCellStyle: { padding: '5px 10px' },
              rowStyle: {
                fontWeight: 400,
                fontSize: '12px',
              },
              headerStyle: {
                fontWeight: 700,
                fontSize: '12px',
                textTransform: 'uppercase',
                color: '#0E1319B2',
                letterSpacing: '0.01em',
              },
            }}
            localization={{
              body: {
                emptyDataSourceMessage: <EmptyDataMessage />,
              },
              toolbar: {
                searchPlaceholder: 'Search',
              },
            }}
            isLoading={loading}
          />

          {editMemberRoles && (
            <Grid item xs={6} className={classes.buttons}>
              <Button
                id="cancel-member-roles"
                variant="outlined"
                onClick={handleCancelEditRoles}
              >
                Cancel
              </Button>
              <Button
                id="save-member-roles"
                variant="contained"
                onClick={updateCreatorRoles}
                disabled={!isSaveEnabled || !isRoleSelected}
              >
                Save
              </Button>
            </Grid>
          )}
          <Dialog
            open={dialogOpen}
            onClose={handleDialogClose}
            maxWidth="xs"
            style={{ top: '5' }}
            className={classes.dialog}
          >
            <DialogContent
              id="role-update-confirmed-dialog"
              style={{ fontWeight: 'bold' }}
            >
              Your project roles are updated successfully.
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
              <Button
                id="role-dialog-close-button"
                variant="contained"
                size="small"
                onClick={handleDialogClose}
                style={{ backgroundColor: 'black' }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );
};
