import {
  DiscoveryApi,
  FetchApi,
  createApiRef,
} from '@backstage/core-plugin-api';
import { CustomErrorBase } from '@backstage/errors';
import omitBy from 'lodash/omitBy';
import isEmpty from 'lodash/isEmpty';
import { QueryParams } from '../types';
import {
  EntityResponse,
  TagResponse,
  QuestionResponse,
  QuestionsResponse,
  AnswerResponse,
  AnswersResponse,
  AttachmentResponseBody,
  StatisticResponse,
  Question,
  QuestionCreateRequest,
  FrequentlyAskedQuestionsResponse,
} from 'usg-types';

const qetaApiRef = createApiRef<QetaClient>({
  id: 'plugin.qeta.service',
});
class QetaError extends CustomErrorBase {
  errors;
  constructor(message: string, errors: Error[]) {
    super(message);
    this.errors = errors;
  }
}
class QetaClient {
  fetchApi;
  discoveryApi;
  constructor(options: { fetchApi: FetchApi; discoveryApi: DiscoveryApi }) {
    this.fetchApi = options.fetchApi;
    this.discoveryApi = options.discoveryApi;
  }
  async getBaseUrl(): Promise<string> {
    return this.discoveryApi.getBaseUrl('qeta');
  }
  async getQuestions(options: QueryParams): Promise<QuestionsResponse> {
    const query = this.getQueryParameters(options).toString();
    let url = `${await this.getBaseUrl()}/questions`;
    if (query) {
      url += `?${query}`;
    }
    const response = await this.fetchApi.fetch(url);
    if (response.status === 403) {
      return { questions: [], total: 0 };
    }
    const data = await response.json();
    if ('errors' in data) {
      throw new QetaError('Failed to fetch', data.errors);
    }
    return data;
  }
  async getQuestionsList(type: string): Promise<QuestionsResponse> {
    const query = new URLSearchParams({ limit: '10' }).toString();
    let url = `${await this.getBaseUrl()}/questions/list/${type}`;
    if (query) {
      url += `?${query}`;
    }
    const response = await this.fetchApi.fetch(url);
    if (response.status === 403) {
      return { questions: [], total: 0 };
    }
    const data = await response.json();
    if ('errors' in data) {
      throw new QetaError('Failed to fetch', data.errors);
    }
    return data;
  }
  async getFaqsList(): Promise<FrequentlyAskedQuestionsResponse> {
    const query = new URLSearchParams({ limit: '10' }).toString();
    let url = `${await this.getBaseUrl()}/faqs`;
    if (query) {
      url += `?${query}`;
    }
    const response = await this.fetchApi.fetch(url);
    if (response.status === 403) {
      return { questions: [], total: 0 };
    }
    const data = await response.json();
    if ('errors' in data) {
      throw new QetaError('Failed to fetch', data.errors);
    }
    return data;
  }
  async postQuestion(
    question: QuestionCreateRequest,
  ): Promise<QuestionResponse> {
    const response = await this.fetchApi.fetch(
      `${await this.getBaseUrl()}/questions`,
      {
        method: 'POST',
        body: JSON.stringify(question),
        headers: { 'Content-Type': 'application/json' },
      },
    );
    const data = await response.json();
    if ('errors' in data) {
      throw new QetaError('Failed to fetch', data.errors);
    }
    return data;
  }
  async commentQuestion(
    id: string,
    content: string,
  ): Promise<QuestionResponse> {
    const response = await this.fetchApi.fetch(
      `${await this.getBaseUrl()}/questions/${id}/comments`,
      {
        method: 'POST',
        body: JSON.stringify({ content }),
        headers: { 'Content-Type': 'application/json' },
      },
    );
    const data = await response.json();
    if ('errors' in data) {
      throw new QetaError('Failed to fetch', data.errors);
    }
    return data;
  }
  async deleteQuestionComment(
    questionId: number,
    id: number,
  ): Promise<QuestionResponse> {
    const response = await this.fetchApi.fetch(
      `${await this.getBaseUrl()}/questions/${questionId}/comments/${id}`,
      {
        method: 'DELETE',
      },
    );
    const data = await response.json();
    if ('errors' in data) {
      throw new QetaError('Failed to fetch', data.errors);
    }
    return data;
  }
  async getQuestion(
    id: number | string,
    recordView: boolean = false,
  ): Promise<QuestionResponse> {
    if (!id) {
      throw new QetaError('Invalid id provided', [new Error('invalid qid')]);
    }
    const response = await this.fetchApi.fetch(
      `${await this.getBaseUrl()}/questions/${id}${
        recordView ? '?recordView=true' : ''
      }`,
    );
    const data = await response.json();
    if ('errors' in data || !data) {
      throw new QetaError('Failed to fetch', data.errors);
    }
    return data;
  }
  async getTags(): Promise<TagResponse> {
    const response = await this.fetchApi.fetch(
      `${await this.getBaseUrl()}/tags`,
    );
    return await response.json();
  }
  async getEntities(): Promise<EntityResponse> {
    const response = await this.fetchApi.fetch(
      `${await this.getBaseUrl()}/entities`,
    );
    return await response.json();
  }
  async voteQuestionUp(id: number): Promise<QuestionResponse> {
    if (!id) {
      throw new QetaError('Invalid id provided', [new Error('invalid qid')]);
    }
    const response = await this.fetchApi.fetch(
      `${await this.getBaseUrl()}/questions/${id}/upvote`,
    );
    const data = await response.json();
    if ('errors' in data) {
      throw new QetaError('Failed to fetch', data.errors);
    }
    return data;
  }
  async voteQuestionDown(id: number): Promise<QuestionResponse> {
    if (!id) {
      throw new QetaError('Invalid id provided', [new Error('invalid qid')]);
    }
    const response = await this.fetchApi.fetch(
      `${await this.getBaseUrl()}/questions/${id}/downvote`,
    );
    const data = await response.json();
    if ('errors' in data) {
      throw new QetaError('Failed to fetch', data.errors);
    }
    return data;
  }
  async unvoteQuestion(id: number): Promise<QuestionResponse> {
    if (!id) {
      throw new QetaError('Invalid id provided', [new Error('invalid qid')]);
    }
    const response = await this.fetchApi.fetch(
      `${await this.getBaseUrl()}/questions/${id}/unvote`,
    );
    const data = await response.json();
    if ('errors' in data) {
      throw new QetaError('Failed to fetch', data.errors);
    }
    return data;
  }
  async favoriteQuestion(id: number): Promise<QuestionResponse> {
    if (!id) {
      throw new QetaError('Invalid id provided', [new Error('invalid qid')]);
    }
    const response = await this.fetchApi.fetch(
      `${await this.getBaseUrl()}/questions/${id}/favorite`,
    );
    const data = await response.json();
    if ('errors' in data) {
      throw new QetaError('Failed to fetch', data.errors);
    }
    return data;
  }
  async unfavoriteQuestion(id: number): Promise<QuestionResponse> {
    if (!id) {
      throw new QetaError('Invalid id provided', [new Error('invalid qid')]);
    }
    const response = await this.fetchApi.fetch(
      `${await this.getBaseUrl()}/questions/${id}/unfavorite`,
    );
    const data = await response.json();
    if ('errors' in data) {
      throw new QetaError('Failed to fetch', data.errors);
    }
    return data;
  }
  async postAnswer(answer: {
    answer: string;
    images?: string[];
    anonymous?: boolean;
    questionId: number | string;
  }): Promise<AnswerResponse> {
    const response = await this.fetchApi.fetch(
      `${await this.getBaseUrl()}/questions/${answer.questionId}/answers`,
      {
        method: 'POST',
        body: JSON.stringify({
          answer: answer.answer,
          images: answer.images,
          anonymous: answer.anonymous,
        }),
        headers: { 'Content-Type': 'application/json' },
      },
    );
    const data = await response.json();
    if ('errors' in data) {
      throw new QetaError('Failed to fetch', data.errors);
    }
    return data;
  }
  async commentAnswer(
    questionId: number,
    id: number,
    content: string,
  ): Promise<AnswerResponse> {
    const response = await this.fetchApi.fetch(
      `${await this.getBaseUrl()}/questions/${questionId}/answers/${id}/comments`,
      {
        method: 'POST',
        body: JSON.stringify({ content }),
        headers: { 'Content-Type': 'application/json' },
      },
    );
    const data = await response.json();
    if ('errors' in data) {
      throw new QetaError('Failed to fetch', data.errors);
    }
    return data;
  }
  async deleteAnswerComment(
    questionId: number,
    answerId: number,
    id: number,
  ): Promise<AnswerResponse> {
    const response = await this.fetchApi.fetch(
      `${await this.getBaseUrl()}/questions/${questionId}/answers/${answerId}/comments/${id}`,
      {
        method: 'DELETE',
      },
    );
    const data = await response.json();
    if ('errors' in data) {
      throw new QetaError('Failed to fetch', data.errors);
    }
    return data;
  }
  async voteAnswerUp(questionId: number, id: number): Promise<AnswerResponse> {
    if (!id || !questionId) {
      throw new QetaError('Invalid id provided', [new Error('invalid qid')]);
    }
    const response = await this.fetchApi.fetch(
      `${await this.getBaseUrl()}/questions/${questionId}/answers/${id}/upvote`,
    );
    const data = await response.json();
    if ('errors' in data) {
      throw new QetaError('Failed to fetch', data.errors);
    }
    return data;
  }
  async voteAnswerDown(
    questionId: number,
    id: number,
  ): Promise<AnswerResponse> {
    if (!id || !questionId) {
      throw new QetaError('Invalid id provided', [new Error('invalid qid')]);
    }
    const response = await this.fetchApi.fetch(
      `${await this.getBaseUrl()}/questions/${questionId}/answers/${id}/downvote`,
    );
    const data = await response.json();
    if ('errors' in data) {
      throw new QetaError('Failed to fetch', data.errors);
    }
    return data;
  }
  async unvoteAnswer(questionId: number, id: number): Promise<AnswerResponse> {
    if (!id || !questionId) {
      throw new QetaError('Invalid id provided', [new Error('invalid qid')]);
    }
    const response = await this.fetchApi.fetch(
      `${await this.getBaseUrl()}/questions/${questionId}/answers/${id}/unvote`,
    );
    const data = await response.json();
    if ('errors' in data) {
      throw new QetaError('Failed to fetch', data.errors);
    }
    return data;
  }
  async markAnswerCorrect(questionId: number, id: number): Promise<boolean> {
    if (!id || !questionId) {
      throw new QetaError('Invalid id provided', [new Error('invalid qid')]);
    }
    const response = await this.fetchApi.fetch(
      `${await this.getBaseUrl()}/questions/${questionId}/answers/${id}/correct`,
    );
    const data = await response;
    return data.ok;
  }
  async markAnswerIncorrect(questionId: number, id: number): Promise<boolean> {
    if (!id || !questionId) {
      throw new QetaError('Invalid id provided', [new Error('invalid qid')]);
    }
    const response = await this.fetchApi.fetch(
      `${await this.getBaseUrl()}/questions/${questionId}/answers/${id}/incorrect`,
    );
    const data = await response;
    return data.ok;
  }
  async deleteQuestion(questionId: number): Promise<boolean> {
    if (!questionId) {
      throw new QetaError('Invalid id provided', [new Error('invalid qid')]);
    }
    const response = await this.fetchApi.fetch(
      `${await this.getBaseUrl()}/questions/${questionId}`,
      {
        method: 'DELETE',
      },
    );
    const data = await response;
    return data.ok;
  }
  async deleteAnswer(questionId: number, id: number): Promise<boolean> {
    if (!questionId || !id) {
      throw new QetaError('Invalid id provided', [new Error('invalid qid')]);
    }
    const response = await this.fetchApi.fetch(
      `${await this.getBaseUrl()}/questions/${questionId}/answers/${id}`,
      {
        method: 'DELETE',
      },
    );
    const data = await response;
    return data.ok;
  }
  async updateQuestion(
    id: number,
    question: Partial<Question>,
  ): Promise<QuestionResponse> {
    const response = await this.fetchApi.fetch(
      `${await this.getBaseUrl()}/questions/${id}`,
      {
        method: 'POST',
        body: JSON.stringify(question),
        headers: { 'Content-Type': 'application/json' },
      },
    );
    const data = await response.json();
    if ('errors' in data) {
      throw new QetaError('Failed to update', data.errors);
    }
    return data;
  }
  async updateAnswer(
    id: number,
    answer: {
      answer: string;
      questionId: number;
      images: string[] | Blob[];
    },
  ): Promise<AnswerResponse> {
    const response = await this.fetchApi.fetch(
      `${await this.getBaseUrl()}/questions/${answer.questionId}/answers/${id}`,
      {
        method: 'POST',
        body: JSON.stringify({ answer: answer.answer, images: answer.images }),
        headers: { 'Content-Type': 'application/json' },
      },
    );
    const data = await response.json();
    if ('errors' in data) {
      throw new QetaError('Failed to fetch', data.errors);
    }
    return data;
  }
  async getAnswers(options: QueryParams): Promise<AnswersResponse> {
    const query = this.getQueryParameters(options).toString();
    let url = `${await this.getBaseUrl()}/answers`;
    if (query) {
      url += `?${query}`;
    }
    const response = await this.fetchApi.fetch(url);
    if (response.status === 403) {
      return { answers: [], total: 0 };
    }
    const data = await response.json();
    if ('errors' in data) {
      throw new QetaError('Failed to fetch', data.errors);
    }
    return data;
  }
  async getAnswer(questionId: number, id: number): Promise<AnswerResponse> {
    if (!questionId || !id) {
      throw new QetaError('Invalid id provided', [new Error('invalid qid')]);
    }
    const response = await this.fetchApi.fetch(
      `${await this.getBaseUrl()}/questions/${questionId}/answers/${id}`,
    );
    const data = await response.json();
    if ('errors' in data) {
      throw new QetaError('Failed to fetch', data.errors);
    }
    return data;
  }
  async postAttachment(file: string | Blob): Promise<AttachmentResponseBody> {
    const qetaUrl = `${await this.getBaseUrl()}/attachments`;
    const formData = new FormData();
    formData.append('image', file);
    const requestOptions = {
      method: 'POST',
      body: formData,
    };
    const response = await this.fetchApi.fetch(qetaUrl, requestOptions);
    return await response.json();
  }
  async getAttachment(uuid: string): Promise<Blob> {
    const qetaUrl = `${await this.getBaseUrl()}/attachments/${uuid}`;
    const response = await this.fetchApi.fetch(qetaUrl);
    return await response.blob();
  }
  async deleteAttachment(uuid: string, questionId: number): Promise<boolean> {
    if (!questionId || !uuid) {
      throw new QetaError('Invalid id provided', [new Error('invalid qid')]);
    }
    const response = await this.fetchApi.fetch(
      `${await this.getBaseUrl()}/questions/${questionId}/attachments/${uuid}`,
      {
        method: 'DELETE',
      },
    );
    const data = await response;
    return data.ok;
  }
  async getMostUpvotedAnswers(options: {
    options: QueryParams;
  }): Promise<StatisticResponse> {
    const query = this.getQueryParameters(options.options).toString();
    let url = `${await this.getBaseUrl()}/statistics/answers/top-upvoted-users`;
    if (query) {
      url += `?${query}`;
    }
    const response = await this.fetchApi.fetch(url);
    const data = await response.json();
    return data;
  }
  async getMostUpvotedCorrectAnswers(options: {
    options: QueryParams;
  }): Promise<StatisticResponse> {
    const query = this.getQueryParameters(options.options).toString();
    let url = `${await this.getBaseUrl()}/statistics/answers/top-correct-upvoted-users`;
    if (query) {
      url += `?${query}`;
    }
    const response = await this.fetchApi.fetch(url);
    const data = await response.json();
    return data;
  }
  async getMostUpvotedQuestions(options: {
    options: QueryParams;
  }): Promise<StatisticResponse> {
    const query = this.getQueryParameters(options.options).toString();
    let url = `${await this.getBaseUrl()}/statistics/questions/top-upvoted-users`;
    if (query) {
      url += `?${query}`;
    }
    const response = await this.fetchApi.fetch(url);
    const data = await response.json();
    return data;
  }
  async getMostQuestions(options: {
    options: QueryParams;
  }): Promise<StatisticResponse> {
    const query = this.getQueryParameters(options.options).toString();
    let url = `${await this.getBaseUrl()}/statistics/questions/most-questions`;
    if (query) {
      url += `?${query}`;
    }
    const response = await this.fetchApi.fetch(url);
    const data = await response.json();
    return data;
  }
  async getMostAnswers(options: {
    options: QueryParams;
  }): Promise<StatisticResponse> {
    const query = this.getQueryParameters(options.options).toString();
    let url = `${await this.getBaseUrl()}/statistics/answers/most-answers`;
    if (query) {
      url += `?${query}`;
    }
    const response = await this.fetchApi.fetch(url);
    const data = await response.json();
    return data;
  }
  async getTopStatisticsHomepage(options: {
    options: QueryParams;
  }): Promise<StatisticResponse[]> {
    const response = await Promise.all([
      this.getMostQuestions(options),
      this.getMostAnswers(options),
      this.getMostUpvotedQuestions(options),
      this.getMostUpvotedAnswers(options),
      this.getMostUpvotedCorrectAnswers(options),
    ]);
    return response;
  }
  getQueryParameters(params: QueryParams): URLSearchParams {
    const asStrings = Object.fromEntries(
      Object.entries(params).map(([k, v]) => {
        if (!v) {
          return [k, ''];
        }
        if (Array.isArray(v)) {
          return [k, v.join(',')];
        }
        return [k, `${v}`];
      }),
    );
    return new URLSearchParams(omitBy(asStrings, isEmpty));
  }
}

export { QetaClient, QetaError, qetaApiRef };
