import React from 'react';
import {
  Grid,
  InputLabel,
  TextField,
  Button,
  Typography,
  MenuItem,
  Select,
  FormControl,
  OutlinedInput,
  FormControlLabel,
  Radio,
  RadioGroup,
  Checkbox,
  FormGroup,
} from '@material-ui/core';
import { Form, Formik } from 'formik';
import {
  RESOURCE_NAME_MAX_LENGTH,
  RESOURCE_DESCRIPTION_MAX_LENGTH,
  RESOURCE_NAME_MIN_LENGTH,
  SPECIAL_RESOURCE_NAME_REGEX,
  ADMIN,
} from 'usg-types';
import { ContactUsLink } from '@internal/sg-ui-kit';
import {
  transformResourceName,
  getLocalStorageWithExpiry,
  getTechDocsLink,
} from 'sg-utils-frontend';
import { Link } from 'react-router-dom';

import { useStyles } from '../styles';

interface Props {
  onSubmitCreateArtifactorySaaS: (values: any) => Promise<void>;
  artifactoryNameDirty: boolean;
  onArtifactoryNameClick: () => void;
  descriptionDirty: boolean;
  onDescriptionClick: () => void;
  apiLoader: boolean;
  packageType: Array<String>;
  userRole: String;
}
export const CreateArtifactorySaaSResourceForm = ({
  onSubmitCreateArtifactorySaaS,
  artifactoryNameDirty,
  onArtifactoryNameClick,
  descriptionDirty,
  onDescriptionClick,
  apiLoader,
  packageType,
  userRole,
}: Props) => {
  const language = getLocalStorageWithExpiry('locale');
  const classes = useStyles();
  const sites = ['jp', 'us'];
  const resourceName = transformResourceName('artifactorysaas');

  const memoisedRClass = React.useMemo(() => {
    return [ADMIN].includes(userRole.toString())
      ? ['local', 'remote', 'virtual', 'federated']
      : ['local', 'federated'];
  }, [userRole]);

  const handleRadioButtonChange = (event: any, formik: any) => {
    formik.setFieldValue('sites', [event.target.value]);
  };

  const handleCheckBoxChange = (event: any, formik: any) => {
    if (event.target.checked) {
      formik.setFieldValue('sites', [
        ...formik.values.sites,
        event.target.value,
      ]);
    } else {
      formik.setFieldValue(
        'sites',
        formik.values.sites.filter((v: any) => v !== event.target.value),
      );
    }
  };

  const RClassHelpFieldSection = () => {
    return (
      <>
        <Typography className={classes.artifactoryOwnerAlertText}>
          {`Virtual and Remote repositories can not be created
            automatically.`}
          <br />
          {`Please `}
          <ContactUsLink
            text="contact us to raise a ticket"
            language={language}
            target="_blank"
          />
          {` to our Service Desk for these repositories instead.`}
        </Typography>
        <br />
      </>
    );
  };

  const RClassField = ({ formik }: { formik: any }) => {
    return (
      <FormControl fullWidth className={classes.formControl}>
        <InputLabel htmlFor="r_class">R Class</InputLabel>
        <Select
          id="r_class"
          name="r_class"
          data-testid="r_class_select"
          value={formik.values.r_class}
          input={<OutlinedInput label="Tag" />}
          onChange={e => {
            e.preventDefault();
            formik.handleChange(e);
          }}
          style={{ textTransform: 'capitalize' }}
          displayEmpty
        >
          <MenuItem value="" disabled>
            Select
          </MenuItem>
          {memoisedRClass.map((value: any) => {
            return (
              <MenuItem
                value={value}
                style={{ textTransform: 'capitalize' }}
                key={value}
              >
                {value}
              </MenuItem>
            );
          })}
        </Select>
        {formik.values.r_class === 'local' && (
          <Typography className={classes.artifactoryOwnerAlertText}>
            Create a local Artifactory repository in either Japan or the US by
            selecting the region and access the repository using the appropriate
            regional URL.{' '}
            <Link
              className={classes.hyperLink}
              target="_blank"
              to={getTechDocsLink('artifactory-saas-standard')}
            >
              Learn more.
            </Link>
          </Typography>
        )}
        {formik.values.r_class === 'federated' && (
          <>
            <Typography className={classes.artifactoryOwnerAlertText}>
              Federated repositories on Stargate Portal enable full data
              replication between two regions, but be aware that this will
              double storage costs and incur additional replication traffic
              expenses compared to repositories.{' '}
              <Link
                className={classes.hyperLink}
                target="_blank"
                to={getTechDocsLink('artifactory-saas-standard')}
              >
                Learn more.
              </Link>
            </Typography>
          </>
        )}
      </FormControl>
    );
  };

  const displaySitesForAdmin = (formik: any) => {
    return (
      userRole === ADMIN &&
      (formik.values.r_class === 'remote' ||
        formik.values.r_class === 'virtual')
    );
  };

  const placeHolderText = (formik: any) => {
    switch (formik?.values?.r_class) {
      case 'local':
        return 'Example : <region>-<artifactory-project-name>-<tech>-<env>-local';
      case 'federated':
        return 'Example : <region>-<artifactory-project-name>-<tech>-<env>-local';
      case 'remote':
        return 'Example : <region>-<artifactory-project-name>-<tech>-remote';
      case 'virtual':
        return 'Example : <region>-<artifactory-project-name>-<tech>-<env>';
      default:
        return '';
    }
  };

  return (
    <Grid item xs={6}>
      <Formik
        initialValues={{
          artifactory_saas_repo_name: '',
          artifactory_saas_description: '',
          package_type: '',
          r_class: 'local',
          sites: [sites[0]],
        }}
        onSubmit={onSubmitCreateArtifactorySaaS}
      >
        {formik => (
          <Form>
            <h3 style={{ color: '#0E4295' }}>{resourceName} Repo</h3>
            <p>
              This will create a repository on Stargate’s Artifactory SaaS
              instance. Please note: to give access to Artifactory SaaS to users
              outside of Toyota companies, you might need to request for their
              IP address to be added an allow list.{' '}
              <Link className={classes.hyperLink} to={getTechDocsLink('vpn')}>
                Learn more.
              </Link>
            </p>
            <br />
            <InputLabel style={{ marginBottom: '8px' }}>Config*</InputLabel>
            {userRole !== ADMIN && <RClassHelpFieldSection />}
            <div style={{ paddingLeft: '24px' }}>
              <FormControl fullWidth>
                <InputLabel htmlFor="package_type">Package Type</InputLabel>
                <Select
                  id="package_type"
                  name="package_type"
                  data-testid="package_type_select"
                  value={formik.values.package_type}
                  input={<OutlinedInput label="Tag" />}
                  onChange={formik.handleChange}
                  displayEmpty
                  style={{ textTransform: 'capitalize' }}
                >
                  <MenuItem value="" disabled>
                    Select
                  </MenuItem>
                  {packageType.map((value: any) => {
                    return (
                      <MenuItem
                        value={value}
                        style={{ textTransform: 'capitalize' }}
                        key={value}
                      >
                        {value}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <br />
              <br />
              <RClassField formik={formik} />
              {/* Sites */}
              {formik.values.r_class === 'local' && (
                <>
                  <FormControl>
                    <InputLabel htmlFor="sites">Sites</InputLabel>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={formik?.values?.sites[0]}
                      onChange={event => {
                        handleRadioButtonChange(event, formik);
                      }}
                      style={{ display: 'flex', flexDirection: 'row' }}
                    >
                      {sites.map(element => {
                        return (
                          <FormControlLabel
                            value={element}
                            control={
                              <Radio
                                id={`projects-createresource-artifactorysaas-site-${element}`}
                              />
                            }
                            label={element.toUpperCase()}
                          />
                        );
                      })}
                    </RadioGroup>
                  </FormControl>
                  <br />
                </>
              )}
              {displaySitesForAdmin(formik) && (
                <FormControl component="fieldset">
                  <InputLabel htmlFor="sites">Sites</InputLabel>
                  <FormGroup
                    aria-label="position"
                    style={{ display: 'flex', flexDirection: 'row' }}
                  >
                    {sites.map(element => {
                      return (
                        <FormControlLabel
                          value={element}
                          onChange={event => {
                            handleCheckBoxChange(event, formik);
                          }}
                          control={
                            <Checkbox
                              id={`projects-createresource-artifactorysaas-site-${element}`}
                              checked={formik.values.sites.includes(element)}
                            />
                          }
                          label={element.toUpperCase()}
                        />
                      );
                    })}
                  </FormGroup>
                </FormControl>
              )}
            </div>
            <br />
            <InputLabel htmlFor="artifactory_saas_repo_name">
              {resourceName} Repo Name*
            </InputLabel>
            <TextField
              style={{ paddingBottom: '0%' }}
              type="text"
              id="artifactory_saas_repo_name"
              name="artifactory_saas_repo_name"
              data-testid="artifactory_saas_repo_name"
              required
              error={
                (formik?.values?.artifactory_saas_repo_name?.length <
                  RESOURCE_NAME_MIN_LENGTH.artifactory &&
                  artifactoryNameDirty) ||
                (formik?.values?.artifactory_saas_repo_name?.length >
                  RESOURCE_NAME_MAX_LENGTH.artifactory &&
                  artifactoryNameDirty) ||
                (formik?.values?.artifactory_saas_repo_name?.length > 0 &&
                  !formik.values.artifactory_saas_repo_name.match(
                    SPECIAL_RESOURCE_NAME_REGEX.artifactory,
                  ))
              }
              onChange={formik.handleChange}
              value={formik.values.artifactory_saas_repo_name}
              onClick={onArtifactoryNameClick}
              fullWidth
              placeholder={placeHolderText(formik)}
              helperText={`Provide a unique name for your ${resourceName} repo. Names should consist only of letters, numbers and single hyphens, must not start with a number, and must not end with a hyphen or 'cache'. Number of characters allowed: Minimum=${RESOURCE_NAME_MIN_LENGTH.artifactory}, Maximum=${RESOURCE_NAME_MAX_LENGTH.artifactory}`}
            />
            <br />
            <br />
            <InputLabel htmlFor="artifactory_saas_description">
              Description
            </InputLabel>
            <TextField
              type="text"
              id="artifactory_saas_description"
              name="artifactory_saas_description"
              onChange={formik.handleChange}
              value={formik.values.artifactory_saas_description}
              minRows={4}
              multiline
              fullWidth
              error={
                formik.values.artifactory_saas_description.length >
                  RESOURCE_DESCRIPTION_MAX_LENGTH && descriptionDirty
              }
              helperText={`Provide a brief description for your ${resourceName} repo. Number of characters allowed Max = ${RESOURCE_DESCRIPTION_MAX_LENGTH}`}
              placeholder="Example : Repository for storing the artifacts for development within the One-click delivery automation solutions project."
              onClick={onDescriptionClick}
            />
            <br />
            <br />
            <Button
              type="submit"
              id="submit-artifactory-saas"
              data-testid="submit-artifactory-saas"
              disabled={
                formik.values.artifactory_saas_repo_name.length <
                  RESOURCE_NAME_MIN_LENGTH.artifactory ||
                formik.values.artifactory_saas_repo_name.length >
                  RESOURCE_NAME_MAX_LENGTH.artifactory ||
                (formik.values.artifactory_saas_repo_name.length > 0 &&
                  !formik.values.artifactory_saas_repo_name.match(
                    SPECIAL_RESOURCE_NAME_REGEX.artifactory,
                  )) ||
                apiLoader ||
                formik.values.package_type === '' ||
                formik.values.r_class === '' ||
                formik.values.artifactory_saas_description.length >
                  RESOURCE_DESCRIPTION_MAX_LENGTH ||
                formik.values.sites.length === 0
              }
              variant="contained"
            >
              Submit
            </Button>
          </Form>
        )}
      </Formik>
    </Grid>
  );
};
