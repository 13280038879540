import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { EmptyDataMessage } from '@internal/sg-ui-kit';
import { Typography, Button, Tooltip } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { PageLayout, Tabs } from '@internal/sg-ui-kit';
import { useStyles } from './styles';
import { useRouteRef } from '@backstage/core-plugin-api';
import { rootRouteRef } from '../../routes';
import { useAsyncFn } from 'react-use';
import {
  useApi,
  microsoftAuthApiRef,
  errorApiRef,
} from '@backstage/core-plugin-api';
import { projectApiRef } from '../../api';
import { Progress } from '@backstage/core-components';
import { ProjectOwners } from '../../pages/ProjectDetails/ProjectOwners';
import { useNavigate } from 'react-router-dom';
import { Table, TableColumn } from '@backstage/core-components';
import ResourcesTab, { AttachedResourcesByDevTool } from './ResourcesTab';
import { DetachUserGroup } from '../../components/DetachUserGroup/DetachUserGroup';
import { getTechDocsLink, transformResourceName } from 'sg-utils-frontend';
import { DEFAULT_TABLE_PAGE_SIZE_OPTIONS } from 'usg-types';
import InfoIcon from '@material-ui/icons/Info';
import { usePermissions } from '@internal/plugin-projects';

export interface AttachedUserGroupDetailComponentProps {
  projectId: string;
  groupId: string;
  isAdmin: boolean;
  isOwner: boolean;
  userLoading: boolean;
}

export const AttachedUserGroupDetailPageComponent = ({
  projectId,
  groupId,
  isAdmin,
  isOwner,
  userLoading,
}: AttachedUserGroupDetailComponentProps) => {
  const navigate = useNavigate();
  const classes = useStyles();

  const rootLink = useRouteRef(rootRouteRef);
  const projectApi = useApi(projectApiRef);
  const authref = useApi(microsoftAuthApiRef);
  const errorApi = useApi(errorApiRef);

  const [attachedProjectResources, setAttachedProjectResources] =
    useState<AttachedResourcesByDevTool>({});
  const [attachableProjectResources, setAttachableProjectResources] =
    useState<AttachedResourcesByDevTool>({});
  const [projectData, setProjectData] = useState<any>();
  const [currentUserGroup, setCurrentUserGroup] = useState<any>([]);
  const [sourceProject, setSourceProject] = useState<any>();
  const [showDetachuserGroup, setShowDetachuserGroup] =
    useState<boolean>(false);
  const [isGroupArchived, setIsGroupArchived] = useState(false);
  const [sortingState] = React.useState({
    order: false,
    orderBy: 'username',
  } as any);
  const [tableState, setTableState] = React.useState({
    page: 1,
    pageSize: DEFAULT_TABLE_PAGE_SIZE_OPTIONS[0],
  });
  const [norenUUID, setNorenUUID] = useState('');
  const [devTools, setDevTools] = useState([] as any);
  const [resourceData, setResourceData] = useState([] as any);

  const feedColumns: Array<TableColumn<any>> = [
    {
      field: 'email',
      title: 'Email',
      width: '40%',
      render: ({ email }) => (
        <Link
          to={`/projects/${projectId}/users/${email}`}
          className={classes.hyperLink}
        >
          {email}
        </Link>
      ),
    },
    {
      field: 'displayName',
      title: 'Name',
      width: '40%',
      sorting: false,
    },
  ];

  const toAttachedResourcesByDevTool = (
    group_resources: any,
    project_resource_roles: any,
  ) => {
    const groupAttachedResources: AttachedResourcesByDevTool = {};
    group_resources.forEach((resource: any) => {
      const projectResource = project_resource_roles.find(
        (pr: any) => pr.id === resource.id,
      );

      if (!!projectResource) {
        groupAttachedResources[projectResource.dev_tool_id] =
          groupAttachedResources[projectResource.dev_tool_id] || {};
        groupAttachedResources[projectResource.dev_tool_id].dev_tool_id =
          projectResource.dev_tool_id;
        groupAttachedResources[projectResource.dev_tool_id].dev_tool_name =
          transformResourceName(projectResource.development_tool_name);
        groupAttachedResources[projectResource.dev_tool_id].attachedResources =
          groupAttachedResources[projectResource.dev_tool_id]
            .attachedResources || [];
        groupAttachedResources[
          projectResource.dev_tool_id
        ].attachedResources.push({
          dev_tool_id: projectResource?.dev_tool_id,
          dev_tool_name: transformResourceName(
            projectResource?.development_tool_name,
          ),
          id: projectResource.id,
          key: projectResource.key,
          name: projectResource.name,
          url: projectResource?.url,
          environment: projectResource?.environment,
          role: resource?.role,
          config: resource?.config,
          generated_resource_key: projectResource?.generated_resource_key,
        });
      }
    });

    setAttachedProjectResources(groupAttachedResources);
  };

  const toProjectAttachableResources = (
    group_resources: any,
    project_resource_roles: any,
  ) => {
    const projectAttachableResources: AttachedResourcesByDevTool = {};

    project_resource_roles.forEach((resource: any) => {
      if (!resource?.deleted_on) {
        const projectResource = group_resources.find(
          (pr: any) => pr.id === resource.id,
        );
        if (!projectResource) {
          projectAttachableResources[resource.dev_tool_id] =
            projectAttachableResources[resource.dev_tool_id] || {};
          projectAttachableResources[resource.dev_tool_id].dev_tool_id =
            resource.dev_tool_id;
          projectAttachableResources[resource.dev_tool_id].dev_tool_name =
            transformResourceName(resource.development_tool_name);
          projectAttachableResources[resource.dev_tool_id].attachedResources =
            projectAttachableResources[resource.dev_tool_id]
              .attachedResources || [];
          projectAttachableResources[
            resource.dev_tool_id
          ].attachedResources.push({
            dev_tool_id: resource?.dev_tool_id,
            dev_tool_name: transformResourceName(
              projectResource?.development_tool_name,
            ),
            id: resource.id,
            key: resource.key,
            name: resource.name,
            url: resource?.url,
            environment: resource?.environment,
            role: resource?.role,
            config: resource?.config,
            generated_resource_key: resource?.generated_resource_key,
          });
        }
      }
    });

    setAttachableProjectResources(projectAttachableResources);
  };

  const getUserListData = (query: any): Promise<any> => {
    return new Promise(async (resolve, _reject) => {
      if (!query || !norenUUID) {
        return;
      }

      const idToken = await authref.getIdToken();
      const page = query?.page + 1;
      const pageSize = query?.pageSize;
      const startIndex = query?.page === 0 ? 0 : (page - 1) * query?.pageSize;
      setTableState({ ...tableState, pageSize, page });

      const getUserMemberList: any = await projectApi.getUserMemberList(
        norenUUID,
        pageSize,
        startIndex,
        idToken,
        groupId,
        sortingState.orderBy,
        sortingState.order,
      );

      if (getUserMemberList?.response?.status === 200) {
        resolve({
          data: getUserMemberList.response?.data?.members,
          page: query?.page,
          totalCount: getUserMemberList.response?.data?.total_results,
        });
      } else {
        errorApi.post(
          new Error(
            `Failed to retrieve the user data: ${getUserMemberList?.response?.data?.message}`,
          ),
        );
        return;
      }
    });
  };

  const [{ loading: dtLoading = false as boolean }, fetchDevTools] =
    useAsyncFn(async () => {
      const idToken = await authref.getIdToken();
      const params = {
        idToken: idToken,
      };

      const responseDevTool: any = await projectApi.getDevelopmentToolsData(
        params,
      );

      if (responseDevTool?.response?.status === 200) {
        setDevTools(responseDevTool?.response?.data?.development_tools);
      } else {
        errorApi.post(new Error(`${responseDevTool?.response?.data?.message}`));
        navigate(`/projects/${projectId}`);
      }
    }, []);

  const [{ loading: pLoading = false as boolean }, fetchProject] =
    useAsyncFn(async () => {
      const idToken = await authref.getIdToken();

      const getProjectByIDResponse: any = await projectApi.getProjectByID(
        projectId,
        {
          idToken: idToken,
          manipulators: ['user_groups', 'resources'],
        },
      );

      const response = getProjectByIDResponse?.response;
      if (response?.status !== 200) {
        errorApi.post(new Error(`${response?.data?.message.error}`));
        return;
      }
      const resProjectData = response?.data;
      setProjectData(resProjectData);
      setResourceData(resProjectData?.resources);

      const userGroup = resProjectData?.user_groups?.find((ug: any) => {
        return ug?.id.toString() === groupId.toString() && !ug?.deleted_on;
      });

      if (!userGroup) {
        navigate(`/projects/${projectId}`);
      }

      const groupParams = {
        idToken: idToken,
      };
      const groupRes: any = await projectApi.getGroupByIDFromProject(
        groupId.toString(),
        projectId,
        groupParams,
      );

      const groupNorenData = groupRes?.data;

      if (groupNorenData?.deleted_on) {
        setIsGroupArchived(true);
      }

      if (groupNorenData?.noren_uuid) {
        setNorenUUID(groupNorenData?.noren_uuid);
      }

      setCurrentUserGroup(userGroup);

      if (userGroup?.noren_uuid) {
        setNorenUUID(userGroup?.noren_uuid);
      }
      toProjectAttachableResources(
        userGroup?.accessible_resources_roles,
        resProjectData?.resources,
      );
      toAttachedResourcesByDevTool(
        userGroup?.accessible_resources_roles,
        resProjectData?.resources,
      );

      if (userGroup?.sharing_properties?.source_project_id !== projectId) {
        const res: any = await projectApi.getProjectByID(
          userGroup?.sharing_properties?.source_project_id,
          {
            idToken: idToken,
            manipulators: ['user_groups', 'resources'],
          },
        );
        if (res?.response?.status !== 200) {
          errorApi.post(
            new Error(
              `Failed retrieving source project. ${response?.data?.message}`,
            ),
          );
          navigate(`/projects/${projectId}`);

          return;
        }
        setSourceProject(res?.response?.data);
      }
    }, []);

  const [
    { loading: ugResourcesLoading = false as boolean },
    fetchUserGroupResources,
  ] = useAsyncFn(async pID => {
    const res = (await authref.getIdToken().then(async (token: any) => {
      const params = {
        idToken: token,
        manipulators: ['user_groups', 'resources'],
      };

      return await projectApi.getProjectByID(pID, params);
    })) as any;

    const code = res?.response?.status;
    const data = res?.response?.data;

    if (code !== 200) {
      errorApi.post(new Error(`${res?.response?.data?.message}`));
      navigate(`/projects/${projectId}`);
      return;
    }

    const userGroup = data?.user_groups?.find((ug: any) => {
      return ug?.id.toString() === groupId.toString() && !ug?.deleted_on;
    });

    setCurrentUserGroup(userGroup);
    toProjectAttachableResources(
      userGroup?.accessible_resources_roles,
      data?.resources,
    );
    toAttachedResourcesByDevTool(
      userGroup?.accessible_resources_roles,
      data?.resources,
    );
  }, []);

  React.useEffect(
    () => {
      const fetchInitialData = async () => {
        fetchDevTools();
        fetchProject();
      };
      fetchInitialData();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  // Back to Projects list page
  const backToTarget = projectId
    ? `/projects/${projectId}?active=1`
    : rootLink();

  const backToLink = React.useMemo(
    () => ({
      to: backToTarget,
      label: projectId ? 'Back to Project' : 'Back to Projects',
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [backToTarget],
  );

  const renderUsersTable = () => {
    return (
      <div>
        {pLoading ? <Progress /> : ''}
        <Table
          columns={feedColumns}
          options={{
            paging: true,
            search: false,
            sorting: false,
            actionsColumnIndex: -1,
            padding: 'dense',
            pageSize: tableState.pageSize,
            pageSizeOptions: DEFAULT_TABLE_PAGE_SIZE_OPTIONS,
            emptyRowsWhenPaging: false,
            actionsCellStyle: { padding: '5px 10px' },
          }}
          data={norenUUID.length === 0 ? [] : query => getUserListData(query)}
          title="All Users"
          localization={{
            body: {
              emptyDataSourceMessage: (
                <EmptyDataMessage
                  message={
                    norenUUID.length > 0
                      ? 'This user group is empty.'
                      : 'User Group is being attached...'
                  }
                />
              ),
            },
          }}
          actions={[]}
        />
      </div>
    );
  };

  const MainSection = () => {
    return (
      <>
        <Typography
          variant="h4"
          display="inline"
          className={classes.resourceText}
        >
          {currentUserGroup?.name?.toUpperCase()}
        </Typography>
        <Typography
          variant="h4"
          display="inline"
          style={{ marginLeft: 10, fontWeight: 'normal' }}
          className={classes.resourceText}
        >
          (Attached group)
        </Typography>
        <div style={{ paddingTop: '12px' }}>
          {sourceProject?.name ? (
            <Link
              className={classes.resourceTextLink}
              to={`/projects/${
                currentUserGroup?.sharing_properties?.source_project_id ||
                projectId
              }`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {sourceProject?.name}
            </Link>
          ) : (
            <div className={classes.unauthorizedProjectDiv}>
              <i>
                Unauthorized Project (
                {currentUserGroup?.sharing_properties?.source_project_id ||
                  projectId}
                )
              </i>
              <Tooltip
                interactive
                classes={{
                  tooltip: classes.tooltip,
                  arrow: classes.tooltipArrow,
                }}
                title={
                  <div style={{ textAlign: 'center' }}>
                    You do not have permission to view this project,{' '}
                    <Link
                      className={classes.hyperlink}
                      target="_blank"
                      to={getTechDocsLink('project-access')}
                    >
                      learn more here
                    </Link>
                  </div>
                }
                arrow
                placement="top"
              >
                <InfoIcon color="primary" className={classes.projectInfoIcon} />
              </Tooltip>
            </div>
          )}
        </div>
        <p style={{ marginTop: 15 }}>{currentUserGroup?.description}</p>
        <p style={{ marginTop: 15 }}>
          This group is an external group, you are only allowed to manage the
          resource access to this project on this screen.
        </p>

        {(isAdmin || isOwner) && (
          <Button
            variant="outlined"
            style={{ float: 'right', marginTop: '10px' }}
            id="detach-user-group-button"
            onClick={() => {
              setShowDetachuserGroup(true);
            }}
            disabled={ugResourcesLoading}
          >
            DETACH USER GROUP
          </Button>
        )}
      </>
    );
  };

  const tabs = [
    {
      label: 'ASSIGNED RESOURCES',
      value: 'assigned_resources',
      content: (
        <ResourcesTab
          fetchUserGroupResources={fetchUserGroupResources}
          attachedResourcesByDevTool={attachedProjectResources}
          groupData={currentUserGroup}
          projectId={projectId}
          groupId={groupId}
          attachableProjectResources={attachableProjectResources}
          devTools={devTools}
          loadingFetchUserGroupResources={ugResourcesLoading}
          isAdmin={isAdmin}
          isOwner={isOwner}
          resourceData={resourceData}
          isGroupArchived={isGroupArchived}
        />
      ),
    },
    {
      label: 'MEMBERS',
      value: 'members',
      content: <div>{renderUsersTable()}</div>,
    },
  ];

  if (dtLoading || pLoading || userLoading) {
    return <Progress />;
  }

  return (
    <>
      <PageLayout
        type="entity"
        title={projectData?.name || ''}
        headerAdditionalControls={
          <ProjectOwners owners={projectData?.owners || null} />
        }
        backToLink={backToLink}
        children={undefined}
      />
      <div className={classes.container} style={{ marginTop: '18px' }}>
        <MainSection />
        <Tabs tabs={tabs} />
      </div>

      <DetachUserGroup
        navigate={navigate}
        isVisible={showDetachuserGroup}
        handleClose={() => setShowDetachuserGroup(false)}
        projectId={projectId}
        groupId={groupId}
        groupName={currentUserGroup?.name}
      />
    </>
  );
};

export const AttachedUserGroupDetailPage = () => {
  const { projectId, groupId } = useParams() as {
    projectId: string;
    groupId: string;
  };
  const navigate = useNavigate();
  const errorApi = useApi(errorApiRef);
  const {
    isAdmin,
    isProjectOwner,
    isProjectMember,
    isLoading: isLoadingPermissions,
    error: permissionsError,
  } = usePermissions();

  const isOwner = isProjectOwner(parseInt(projectId, 10));

  if (!isAdmin && !isProjectMember(parseInt(projectId, 10))) {
    errorApi.post(new Error(`User is not a member of the project.`));
    navigate('/projects');
  }

  if (permissionsError) {
    errorApi.post(new Error(`${permissionsError?.message}`));
    navigate('/projects');
  }

  if (isLoadingPermissions) {
    return <Progress />;
  }

  return (
    <AttachedUserGroupDetailPageComponent
      projectId={projectId}
      groupId={groupId}
      isAdmin={isAdmin}
      isOwner={isOwner}
      userLoading={isLoadingPermissions}
    />
  );
};
