import React, { useState, useMemo, useEffect, useRef } from 'react';
import { useForm, useFormState } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useApi,
  microsoftAuthApiRef,
  configApiRef,
} from '@backstage/core-plugin-api';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { Alert, Skeleton } from '@material-ui/lab';
import { Progress } from '@backstage/core-components';
import { useLocation } from 'react-router-dom';
import { Layout } from '../Layout';
import { definitions } from '../../api';
import { mtfujiApiRef } from '../../fetcher';
import './Styles.css';
import { ApplicationName, generateRandomName } from './ApplicationName';
import { GithubRepository } from './GithubRepository';
import { ArtifactoryRepository } from './ArtifactoryRepository';
import {
  UserGroups,
  getDefaultReadWriteGroupName,
  getDefaultReadOnlyGroupName,
} from './UserGroups';
import { ActiveClusters } from './Clusters';
import { Preview } from './Preview';
import { Pending } from './Pending';
import OverviewCard, { OverviewItem } from './Card';
import {
  getArtifactoryRegistryFQDN,
  getRepo,
  isEmptyOrNil,
} from './FormHelpers';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { ProjectSelector } from './ProjectSelector';
import { projectApiRef } from '@internal/plugin-projects';
import {
  GITHUBEMU_DEV_TOOL_ID,
  GITHUB_DEV_TOOL_ID,
  ARTIFACTORY_SAAS_DEV_TOOL_ID,
  ARTIFACTORY_DEV_TOOL_ID,
  MTFUJI_DEV_TOOL_ID,
  ADMIN,
  OWNER,
} from 'usg-types';
import { getRoles } from '@internal/sg-utils-common';
import { isEmpty, isNil } from 'lodash';
import {
  GITHUB_HOST,
  ARTIFACTORY_SAAS_PROD_HOST,
  ARTIFACTORY_SAAS_NON_PROD_HOST,
  ARTIFACTORY_SAAS_NAME,
  ARTIFACTORY_PROD_HOST,
  ARTIFACTORY_NON_PROD_HOST,
  ARTIFACTORY_SELF_HOSTED_NAME,
} from './Constants';
import retry from 'async-retry';

const CICD_SECTION = 2;
const USERGROUP_SECTION = 3;
// Define resource manager here since it is not available in usg-types yet
const RESOURCE_MANAGER = 'resource_manager';

type ApiConfigClusterType = definitions['config.MountFujiCluster'];
type NamespaceRequestArtifactoryRepositoryType =
  definitions['handlers.NamespaceRequestArtifactoryRepository'];
type NamespaceAutocompletionResponseProjectType =
  definitions['handlers.NamespaceAutocompletionResponseProject'];
type ApplicationResponse = definitions['handlers.ApplicationResponse'];
type ApplicationEnvAADGroup = definitions['handlers.AADGroup'];
export type ApplicationRequest = definitions['handlers.ApplicationRequest'];
export type ApplicationEnv = definitions['handlers.ApplicationEnv'];
export type ArtifactoryRegistry = definitions['handlers.ArtifactoryRegistry'];
export type ApplicationGitHubRepository =
  definitions['handlers.GitHubRepository'];
type Cluster = definitions['handlers.Cluster'];

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

const useStyles = makeStyles((_: Theme) =>
  createStyles({
    dialogGrid: {
      margin: '20px',
      width: 'fit-content',
    },
  }),
);

// Form default value query param names
const appNameParam = 'name';
const githubHostParam = 'gh_h';
const githubOwnerParam = 'gh_o';
const githubRepoParam = 'gh_r';
const artifactoryHostParam = 'rt_h';
const artifactoryRepoParam = 'rt_r';
const clustersParam = 'cl';
const developerGroupsParam = 'dg';
const readonlyGroupsParam = 'rg';

export type ApplicationRequestProps = {
  app_name: string;
  request: ApplicationRequest;
  readonly_group: ApplicationEnvAADGroup[];
  developer_group: ApplicationEnvAADGroup[];
  github_repository: ApplicationGitHubRepository;
  artifactory_registries: ArtifactoryRegistry[];
  active_clusters: ApiConfigClusterType[];
};

// props schema
export type WizardProps = {
  stargateProjectId?: number;
};

function canEditResource(
  userData: any,
  projectId: number,
  resourceName: string,
): boolean {
  const userRoles = getRoles(userData?.roles);
  const projectDetails: any = userData?.projects.find(
    (p: any) => p.id === projectId,
  );
  return (
    projectDetails?.is_owner ||
    (userRoles && (userRoles?.includes(ADMIN) || userRoles?.includes(OWNER))) ||
    projectDetails?.resources?.some(
      (resource: any) =>
        resource?.dev_tool_id === MTFUJI_DEV_TOOL_ID &&
        resource?.name === resourceName &&
        resource?.resource_roles?.some(
          (role: string) => role === RESOURCE_MANAGER,
        ),
    )
  );
}

export const Wizard = (props: WizardProps) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const mtfujiApi = useApi(mtfujiApiRef);
  const projectApi = useApi(projectApiRef);
  const authApi = useApi(microsoftAuthApiRef);
  const configApi = useApi(configApiRef);
  const mtfujiApiUrl = configApi.getOptionalString('mtfuji.apiUrl');
  const overviewRef = useRef<null | HTMLDivElement>(null);
  const pendingRef = useRef<null | HTMLDivElement>(null);
  const [activeCategory, setActiveCategory] = useState<number>(-1);
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const [isAppNameDefault, setIsAppNameDefault] = useState<boolean>(true);
  const [maybeNewGithub, setMaybeNewGithub] = useState<boolean>(false);
  const [getAppProgress, setGetAppProgress] = React.useState(0);
  const [confirmProjectSelection, setConfirmProjectSelection] =
    useState<NamespaceAutocompletionResponseProjectType>();
  const [attachResourceUserGroups, setAttachResourceUserGroups] =
    useState<boolean>(true);
  const [applicationNamespaceEnvs, setApplicationNamespaceEnvs] = useState<{
    [key: string]: ApplicationEnv;
  }>({
    prod: {} as ApplicationEnv,
    stage: {} as ApplicationEnv,
    dev: {} as ApplicationEnv,
  });

  const { sgProjectId, applicationName } = useParams() as {
    sgProjectId: string;
    applicationName: string;
  };
  const Operations = {
    Create: 'create',
    Update: 'update',
  };
  const operation: string = isEmptyOrNil(applicationName)
    ? Operations.Create
    : Operations.Update;

  // This state is an object of form {"config_name": boolean} which stores each configuration validity
  // we need this because formState cannot capture the validation of each control after it has
  // been un-rendered from DOM. We pass the setter to each component and make them responsible for
  // setting their own validation. Using the isConfigurationValid func, we can now verify the validity.
  const [configurationValidation, setConfigurationValidation] = React.useState(
    new Map(operation === Operations.Create ? [['user_groups', false]] : []),
  );

  const [currentStargateProjectId, setCurrentStargateProjectId] =
    useState<number>(
      (!isEmpty(sgProjectId)
        ? parseInt(sgProjectId, 10)
        : props.stargateProjectId) || 0,
    );

  const [projectOptions, setProjectOptions] = useState<
    NamespaceAutocompletionResponseProjectType[]
  >([]);

  const query = useQuery();

  const getDefaultArtifactoryHost = (flavor: string) => {
    const isSaasFlavor = flavor === ARTIFACTORY_SAAS_NAME;

    if (mtfujiApiUrl?.includes('mtfuji-api-prod')) {
      return isSaasFlavor ? ARTIFACTORY_SAAS_PROD_HOST : ARTIFACTORY_PROD_HOST;
    }

    return isSaasFlavor
      ? ARTIFACTORY_SAAS_NON_PROD_HOST
      : ARTIFACTORY_NON_PROD_HOST;
  };

  const defaultValues: ApplicationRequestProps = {
    app_name: (query.get(appNameParam) as string) || '',
    request: {
      app_name: (query.get(appNameParam) as string) || '',
      active_clusters: [],
      artifactory_registries: [],
      configuration_base_key: 'prod',
      configuration: {
        prod: {} as ApplicationEnv,
        stage: {} as ApplicationEnv,
        dev: {} as ApplicationEnv,
      },
      destroy: false,
      github_repositories: [],
      preview: true,
      sha: '',
      stargate_project_id: currentStargateProjectId,
      stargate_resource_key: '',
    } as ApplicationRequest,
    readonly_group: [],
    developer_group: [],
    github_repository: {
      host: (query.get(githubHostParam) as string) || GITHUB_HOST,
      owner: (query.get(githubOwnerParam) as string) || '',
      name: (query.get(githubRepoParam) as string) || '',
    } as ApplicationGitHubRepository,
    artifactory_registries: [
      {
        key: (query.get(artifactoryRepoParam) as string) || '',
        name: '',
        host:
          (query.get(artifactoryHostParam) as string) ||
          getDefaultArtifactoryHost(ARTIFACTORY_SAAS_NAME),
        dev_tool_id: ARTIFACTORY_SAAS_DEV_TOOL_ID,
      },
      {
        key: '',
        name: '',
        host:
          (query.get(artifactoryHostParam) as string) ||
          getDefaultArtifactoryHost(ARTIFACTORY_SELF_HOSTED_NAME),
        dev_tool_id: ARTIFACTORY_DEV_TOOL_ID,
      },
    ],
    active_clusters: [],
  };

  const queryClusterIDs = query.get(clustersParam)?.split(',') as string[];
  const queryReadWriteGroups = query
    .get(developerGroupsParam)
    ?.split(',') as string[];
  const queryReadOnlyGroups = query
    .get(readonlyGroupsParam)
    ?.split(',') as string[];

  const { control, getValues, watch, setValue, trigger, reset } =
    useForm<ApplicationRequestProps>({
      mode: 'onChange',
      reValidateMode: 'onChange',
      defaultValues: defaultValues,
      criteriaMode: 'firstError',
      shouldFocusError: true,
      shouldUnregister: false,
    });
  const formState = useFormState({ control });

  const [autocompletionsLoading, setAutocompletionsLoading] =
    useState<boolean>(true);
  const [formError, setFormError] = useState<Error>();
  const [clusterOptions, setClusterOptions] = useState<ApiConfigClusterType[]>(
    [],
  );
  const [repositoryOptions, setRepositoryOptions] = useState<
    ApplicationGitHubRepository[]
  >([]);
  const [groupOptions, setGroupOptions] = useState<ApplicationEnvAADGroup[]>(
    [],
  );
  const [artifactoryRepositoryOptions, setArtifactoryRepositoryOptions] =
    useState<NamespaceRequestArtifactoryRepositoryType[]>([]);
  const [currentStargateProject, setCurrentStargateProject] =
    useState<NamespaceAutocompletionResponseProjectType>(
      {} as NamespaceAutocompletionResponseProjectType,
    );
  const [applicationResponse, setApplicationResponse] =
    useState<ApplicationResponse>({} as ApplicationResponse);
  const [applicationResponseLoading, setApplicationResponseLoading] =
    useState<boolean>(false);
  const [applicationResponseError, setApplicationResponseError] = useState<
    Error | undefined
  >(undefined);
  const [enableEditableButton, setEnableEditableButton] =
    useState<boolean>(true);
  const [showEditUI, setShowEditUI] = useState<boolean>(
    operation === Operations.Create,
  );

  const [selectedGithubResourceID, setSelectedGithubResourceID] =
    useState<number>(0);
  const [selectedArtifactoryResourceIDs, setSelectedArtifactoryResourceIDs] =
    useState<number[]>([]);
  const [selectedROUserGroupResourceIDs, setSelectedROUserGroupResourceIDs] =
    useState<number[]>([]);
  const [selectedRWUserGroupResourceIDs, setSelectedRWUserGroupResourceIDs] =
    useState<number[]>([]);

  const getSGResources = async (): Promise<
    [
      ArtifactoryRegistry[],
      ApplicationGitHubRepository[],
      ApplicationEnvAADGroup[],
    ]
  > => {
    const idToken = await authApi.getIdToken();
    const params = {
      idToken: idToken,
      manipulators: [],
    };
    const ghResources: ApplicationGitHubRepository[] = [];
    const artResources: ArtifactoryRegistry[] = [];
    const userGroups: ApplicationEnvAADGroup[] = [];
    const res = await projectApi.getProjectByID(
      currentStargateProjectId.toString(),
      params,
    );
    if (res.response && res.response.status === 200) {
      res.response.data.resources.forEach((resource: any) => {
        switch (resource.dev_tool_id) {
          case GITHUB_DEV_TOOL_ID:
          case GITHUBEMU_DEV_TOOL_ID:
            ghResources.push({
              dev_tool_id: resource.dev_tool_id,
              host: new URL(resource.url).host,
              key: resource.key,
              name: resource.name,
              owner: resource.key,
            });
            break;
          case ARTIFACTORY_DEV_TOOL_ID:
          case ARTIFACTORY_SAAS_DEV_TOOL_ID:
            artResources.push({
              dev_tool_id: resource.dev_tool_id,
              host: new URL(resource.url).host,
              key: resource.key,
              name: resource.name,
            });
            break;
          default:
            // not interested in any other resources
            // default case required for the linter
            break;
        }
      });
      res.response.data.user_groups.forEach(
        (ug: { id: Number; name: String }) => {
          userGroups.push({
            uuid: ug.id.toString(),
            name: ug.name,
          } as ApplicationEnvAADGroup);
        },
      );
    } else {
      throw Error(
        `Error getting Github and Artifactory resources: ${res?.response}`,
      );
    }
    if (operation === Operations.Update) {
      try {
        const userRes: any = await projectApi.getUserDetails(
          { idToken: idToken },
          { include: 'all' },
        );
        if (userRes.response?.status !== 200) {
          throw new Error('failed to get user details');
        }
        setShowEditUI(
          canEditResource(
            userRes.response.data,
            currentStargateProjectId,
            res.response.data.key,
          ),
        );
      } catch (error) {
        // if there's some error disable edit but allow user to see details
        setShowEditUI(false);
        // TODO: show error so user knows to reload if they need to edit
      }
    }
    return [artResources, ghResources, userGroups];
  };

  const attachResources = async () => {
    const idToken = await authApi.getIdToken();
    const ghOwner = getValues('github_repository.owner');

    // prepare groups with corresponding roles
    type groupRoleType = {
      op: string;
      value: { id: number; role: string; name?: string }[];
    };
    const roGroups = selectedROUserGroupResourceIDs.reduce(
      (a, g) => {
        a.artifactory.push({
          op: 'add',
          value: [{ id: g, role: 'read' }],
        });
        a.github.push({
          op: 'add',
          value: [{ id: g, role: '', name: ghOwner }],
        });
        return a;
      },
      { artifactory: [], github: [] } as {
        artifactory: groupRoleType[];
        github: groupRoleType[];
      },
    );

    const rwGroups = selectedRWUserGroupResourceIDs.reduce(
      (a, g) => {
        a.artifactory.push({
          op: 'add',
          value: [{ id: g, role: 'delete' }],
        });
        a.github.push({
          op: 'add',
          value: [{ id: g, role: '', name: ghOwner }],
        });
        return a;
      },
      { artifactory: [], github: [] } as {
        artifactory: groupRoleType[];
        github: groupRoleType[];
      },
    );

    // Attach groups to artifactory
    const rtParamsData = {
      idToken: idToken,
      userGroupRoles: [...roGroups.artifactory, ...rwGroups.artifactory],
    };

    for (const id of selectedArtifactoryResourceIDs) {
      const rtres = await projectApi.assignUserGroupToResources(
        rtParamsData,
        currentStargateProjectId.toString(),
        id.toString(),
      );

      if (rtres?.status !== 200) {
        throw Error(
          `Error assigning groups for Artifactory: ${rtres?.data.message}`,
        );
      }
    }

    // Attach groups to github org
    const ghParamsData = {
      idToken: idToken,
      userGroupRoles: [...roGroups.github, ...rwGroups.github],
    };

    const ghres = await projectApi.assignUserGroupToResources(
      ghParamsData,
      currentStargateProjectId.toString(),
      selectedGithubResourceID.toString(),
    );
    if (ghres?.status !== 200) {
      throw Error(
        `Error assigning groups for Github Organization: ${ghres?.data.message}`,
      );
    }
  };

  const sendRequest = async (
    preview: boolean = true,
    callback?: (appRes: ApplicationResponse) => void,
  ) => {
    const appName = getValues('app_name');
    const appReq = getValues('request');
    appReq.app_name = appName;
    appReq.stargate_resource_key = appName;
    appReq.preview = preview;

    if (isAppNameDefault) setMaybeNewGithub(true);
    if (!preview) setFormSubmitted(true);
    setEnableEditableButton(false);
    setApplicationResponseLoading(true);
    setApplicationResponseError(undefined);

    try {
      if (operation === Operations.Update) {
        const appResp = await mtfujiApi.updateApplication(
          currentStargateProjectId,
          appName,
          appReq,
        );
        if (!preview && attachResourceUserGroups) {
          attachResources();
        }
        setApplicationResponse(appResp);
      } else {
        const appResp = await mtfujiApi.createApplication(
          currentStargateProjectId,
          appReq,
        );
        if (!preview && attachResourceUserGroups) {
          attachResources();
        }
        setApplicationResponse(appResp);
      }
    } catch (error: any) {
      setApplicationResponseError(error as Error);
      setFormSubmitted(false);
    } finally {
      setApplicationResponseLoading(false);
      if (preview) setEnableEditableButton(true);
      if (callback !== undefined) callback(applicationResponse);
    }
  };

  const isConfigurationValid = () => {
    const values = Array.from(configurationValidation.values());
    return values.every(Boolean) && values.length > 0;
  };

  const handlePreview = () => {
    trigger();
    if (!isConfigurationValid()) return;
    sendRequest(true);
  };

  const handleEdit = (step: number) => {
    setActiveCategory(step);
  };

  const handleFinishedPending = (appResp: ApplicationResponse) => {
    setApplicationResponse(appResp);
    setValue('request.sha', appResp.sha);
    setEnableEditableButton(true);
  };

  const setArtifactoryIntegration = async (
    idToken: string,
    artifactoryDevToolID: number,
    artifactoryKey: string,
  ) => {
    const artifactoryResp: any = await projectApi.getProjectResources(
      currentStargateProjectId.toString(),
      {
        idToken: idToken,
        dev_tool_id: artifactoryDevToolID,
        key: artifactoryKey,
        name: artifactoryKey,
        order_by: 'name',
        order: 'asc',
      },
    );
    if (artifactoryResp?.response?.status !== 200) {
      throw Error(
        `Error fetching Artifactory ID: ${artifactoryResp?.response?.error?.message}`,
      );
    }
    const artifactoryDevTool = artifactoryResp?.response?.data?.resources.find(
      (a: any) => a.key === artifactoryKey,
    );
    if (isNil(artifactoryDevTool)) {
      throw Error(
        `Could not find Image Registry '${artifactoryKey}', please try again`,
      );
    }

    setSelectedArtifactoryResourceIDs([
      ...new Set([...selectedArtifactoryResourceIDs, artifactoryDevTool.id]),
    ]);
  };

  const setGithubIntegration = async (
    idToken: string,
    gh: ApplicationGitHubRepository,
  ) => {
    const githubResp: any = await projectApi.getProjectResources(
      currentStargateProjectId.toString(),
      {
        idToken: idToken,
        dev_tool_id:
          gh.host === 'github.com' ? GITHUBEMU_DEV_TOOL_ID : GITHUB_DEV_TOOL_ID,
        key: gh.owner,
        name: gh.owner,
        order_by: 'name',
        order: 'asc',
      },
    );
    if (githubResp?.response?.status !== 200) {
      throw Error(
        `Error fetching GitHub Organization ID: ${githubResp?.response?.error?.message}`,
      );
    }
    const githubOrgDevTool = githubResp?.response?.data?.resources.find(
      (g: any) => g.key === gh.owner,
    );
    if (isNil(githubOrgDevTool)) {
      throw Error(
        `Could not find GitHub Organization '${gh.owner}', please try again`,
      );
    }
    setSelectedGithubResourceID(githubOrgDevTool.id);
  };

  const setCICDIntegration = async () => {
    const idToken = await authApi.getIdToken();
    const artifactory = getValues('artifactory_registries');
    setSelectedArtifactoryResourceIDs([]);
    setSelectedGithubResourceID(0);

    for (const art of artifactory) {
      if (!isEmpty(art.key)) {
        await setArtifactoryIntegration(idToken, art.dev_tool_id, art.key);
      }
    }

    const githubRepo = getValues('github_repository');
    if (!isEmpty(githubRepo.host) && !isEmpty(githubRepo.owner))
      setGithubIntegration(idToken, githubRepo);
  };

  const setUserGroups = async () => {
    const rwGroup = getValues('developer_group');
    const roGroup = getValues('readonly_group');

    // groups are initially returned as UUIDS but can only be attached to Art/GH as resource IDs
    // so filter out any non-numeric values first
    setSelectedROUserGroupResourceIDs(
      roGroup
        .filter(o => !isNaN(Number(o.uuid)))
        .map(o => parseInt(o.uuid, 10)),
    );

    setSelectedRWUserGroupResourceIDs(
      rwGroup
        .filter(o => !isNaN(Number(o.uuid)))
        .map(o => parseInt(o.uuid, 10)),
    );
  };

  const handleSet = () => {
    if (
      activeCategory === CICD_SECTION ||
      activeCategory === USERGROUP_SECTION
    ) {
      try {
        setCICDIntegration();
        setUserGroups();
      } catch (error: any) {
        setApplicationResponseError(error as Error);
      }
    }
    handlePreview();
    setActiveCategory(-1);
  };

  const aadCachePrefix = 'aad-uuid-cache';
  const setRequestAADGroups = () => {
    const developerGroups = getValues('developer_group');
    const readonlyGroups = getValues('readonly_group');
    const configuration = getValues('request.configuration');
    configuration.prod.aad_readonly_groups = readonlyGroups.map(
      g =>
        ({
          name: g.name,
          uuid: localStorage.getItem(`${aadCachePrefix}-${g.uuid}`),
        } as ApplicationEnvAADGroup),
    );
    configuration.prod.aad_readwrite_groups = developerGroups.map(
      g =>
        ({
          name: g.name,
          uuid: localStorage.getItem(`${aadCachePrefix}-${g.uuid}`),
        } as ApplicationEnvAADGroup),
    );
    setValue('request.configuration', configuration);
    setApplicationNamespaceEnvs(configuration);
    trigger('request.configuration');
  };

  useEffect(() => {
    async function cacheAADUUIDs(groups: ApplicationEnvAADGroup[]) {
      const idToken = await authApi.getIdToken();
      const params = {
        idToken: idToken,
        manipulators: [],
      };
      for (const g of groups) {
        if (isEmptyOrNil(g.uuid)) {
          continue;
        }
        const uuid4regex =
          /^[a-f0-9]{8}-?[a-f0-9]{4}-?4[a-f0-9]{3}-?[89ab][a-f0-9]{3}-?[a-f0-9]{12}$/g;
        if (g.uuid.match(uuid4regex)) {
          localStorage.setItem(`${aadCachePrefix}-${g.uuid}`, g.uuid);
          continue;
        }
        const cached = localStorage.getItem(`${aadCachePrefix}-${g.uuid}`);
        if (isEmptyOrNil(cached)) {
          const res: any = await projectApi.getGroupByIDFromProject(
            g.uuid,
            currentStargateProjectId.toString(),
            params,
          );
          if (res?.data && res?.status === 200) {
            localStorage.setItem(
              `${aadCachePrefix}-${g.uuid}`,
              res.data.external_id,
            );
          }
        }
      }
    }

    const developerGroups = getValues('developer_group');
    const readonlyGroups = getValues('readonly_group');
    Promise.all([
      cacheAADUUIDs(developerGroups),
      cacheAADUUIDs(readonlyGroups),
    ]).then(() => {
      setRequestAADGroups();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, watch(['developer_group', 'readonly_group']));

  useEffect(() => {
    const githubRepo = getValues('github_repository');
    githubRepo.dev_tool_id =
      githubRepo.host === 'github.com'
        ? GITHUBEMU_DEV_TOOL_ID
        : GITHUB_DEV_TOOL_ID;
    githubRepo.key = githubRepo.name;
    setValue('request.github_repositories', [githubRepo]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, watch(['github_repository']));

  useEffect(() => {
    const artifactoryRegistry = getValues('artifactory_registries');
    setValue(
      'request.artifactory_registries',
      artifactoryRegistry.filter(o => !isEmptyOrNil(o.key)),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, watch(['artifactory_registries.0', 'artifactory_registries.1']));

  useEffect(() => {
    const activeClusters = getValues('active_clusters');
    const clusters = activeClusters.flatMap(c => {
      const [prefix, index] = c.name_prefix.split('-');
      return ['apps', 'apps-prod'].map(
        env =>
          ({
            id: `${prefix}-${index}-${env}-${c.region}`,
            infra_env: env,
            pair_index: parseInt(index, 10),
            region: c.region,
            workload_prefix: prefix,
          } as Cluster),
      );
    });
    setValue('request.active_clusters', clusters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, watch(['active_clusters']));

  interface appEnvGroupsNames {
    aad_readonly_groups: ApplicationEnvAADGroup[];
    aad_readwrite_groups: ApplicationEnvAADGroup[];
  }

  const isAppEnvsEmpty = (group: keyof appEnvGroupsNames) => {
    return Object.values(applicationNamespaceEnvs)
      .map(o => (!isEmptyOrNil(o) ? isEmptyOrNil(o[group]) : true))
      .every(Boolean);
  };

  const tabledK8sApiAccess = (group: keyof appEnvGroupsNames) => {
    if (!['aad_readonly_groups', 'aad_readwrite_groups'].includes(group)) {
      throw Error('invalid group name');
    }
    return (
      <>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Env</TableCell>
                <TableCell>Group Name</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(applicationNamespaceEnvs).map(
                ([envName, conf]) =>
                  conf[group] && (
                    <TableRow key={envName}>
                      <TableCell component="th" scope="row">
                        {envName}
                      </TableCell>
                      <TableCell>
                        {conf[group].map(o => o.name).join(', ')}
                      </TableCell>
                    </TableRow>
                  ),
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  };

  const setValidation = (configName: string) => {
    return (isValid: boolean) => {
      setConfigurationValidation(
        configurationValidation.set(configName, isValid),
      );
    };
  };

  const overviewCards: Array<[string, Array<OverviewItem>]> = [
    [
      'Application & Environments',
      [
        {
          name: 'Name',
          values: !!watch('app_name') ? [watch('app_name')] : [],
          description:
            operation !== Operations.Create
              ? ''
              : 'The application name must be unique platform wide and will be part of all your namespaces. A random name was generated for convenience, but the name can not be changed later. Please change it now if required.',
          emptyDescription:
            operation !== Operations.Create
              ? 'The application does not have any name set to it.'
              : 'Please set an application name.',
          editComponent: (
            <ApplicationName
              defaultAppName={watch('app_name')}
              stargateProject={currentStargateProject}
              control={control}
              validationSetter={setValidation('application_name')}
              setValue={setValue}
              getValues={getValues}
              trigger={trigger}
              setIsAppNameDefault={setIsAppNameDefault}
              enableEdit={operation === Operations.Create}
            />
          ),
        },
        {
          name: 'Environments',
          values: ['prod', 'stage', 'dev'],
          description:
            'One Kubernetes namespace per environment will be created.',
          emptyDescription: '',
          editComponent: true,
        },
      ],
    ],
    [
      'Clusters',
      [
        {
          name: 'Clusters',
          values: Array.from(
            watch('active_clusters').reduce(
              (a, c) => a.add(c.label),
              new Set<string>(),
            ),
          ),
          description:
            'One EKS cluster in ap-northeast-1 region was preselected. Multiple EKS and GKE cluster options are available. At least one is required, more can be added at any time.',
          emptyDescription:
            operation !== Operations.Create
              ? 'The application does not have any clusters assigned to it.'
              : 'Please select at least one cluster to deploy on.',
          editComponent: (
            <ActiveClusters
              defaultClusters={watch('active_clusters')}
              clusterOptions={clusterOptions}
              autocompletionsLoading={autocompletionsLoading}
              control={control}
              validationSetter={setValidation('active_clusters')}
              setValue={setValue}
              trigger={trigger}
            />
          ),
        },
      ],
    ],
    [
      'CI/CD Integration',
      [
        {
          name: 'GitHub Repository',
          values:
            getRepo(watch('github_repository')) !== ''
              ? [getRepo(watch('github_repository'))]
              : [],
          description:
            "The linked repository's CI/CD workflows will be granted read-write access to deploy to your namespaces.",
          emptyDescription:
            operation !== Operations.Create
              ? 'The application does not have any GitHub repository set to it.'
              : 'Could not preselect a GitHub organization. Please select an existing one or create a new one to link to your application and enable seamless CI/CD integration.',
          editComponent: (
            <GithubRepository
              stargateProject={currentStargateProject}
              defaultRepo={watch('github_repository')}
              autocompletionsLoading={autocompletionsLoading}
              repositoryOptions={repositoryOptions}
              control={control}
              validationSetter={setValidation('github_repository')}
              setValue={setValue}
              getValues={getValues}
              trigger={trigger}
            />
          ),
        },
        {
          name: 'Container Registry',
          values: [
            getArtifactoryRegistryFQDN(watch('artifactory_registries.0')),
            getArtifactoryRegistryFQDN(watch('artifactory_registries.1')),
          ].filter(o => !isEmptyOrNil(o)),
          description:
            "The linked repository's CI/CD workflows will be granted read-write and your Kubernetes namespaces will be granted read-only access to the linked container registry.",
          emptyDescription:
            operation !== Operations.Create
              ? 'The application does not have any container registry set to it.'
              : 'Could not preselect a container registry. Please select an existing one or create a new one to link it to your application and enable seamless push/pull authorization.',
          editComponent: (
            <>
              <ArtifactoryRepository
                stargateProject={currentStargateProject}
                defaultArtifactoryRepo={watch('artifactory_registries')}
                artifactoryRepositoryOptions={artifactoryRepositoryOptions}
                autocompletionsLoading={autocompletionsLoading}
                control={control}
                validationSetter={setValidation('container_registry')}
                setValue={setValue}
                isFormCreation={operation === Operations.Create}
              />
              <br />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={attachResourceUserGroups}
                    onChange={e => {
                      setAttachResourceUserGroups(e.target.checked);
                    }}
                    name="attach_user_groups_resrouce"
                    color="primary"
                  />
                }
                label="When checked, the same user groups that grant access to the namespaces also grant access to the repository and registry"
              />
            </>
          ),
        },
      ],
    ],
    [
      'Kubernetes API Access',
      [
        {
          name: 'Read-Only Groups',
          valueComponent:
            !isAppEnvsEmpty('aad_readonly_groups') &&
            tabledK8sApiAccess('aad_readonly_groups'),
          description:
            'Group members are granted read-only access to the Kubernetes namespaces. At least one group is required.',
          emptyDescription:
            operation !== Operations.Create
              ? 'The application does not have any read-only groups assigned to it.'
              : 'Could not preselect a group to grant read-only access. Please select an existing group or create a new one to to grant read-only access to your namespaces.',
          editComponent: (
            <UserGroups
              stargateProject={currentStargateProject}
              appName={watch('app_name')}
              defaultReadOnlyGroups={watch('readonly_group')}
              defaultReadWriteGroups={watch('developer_group')}
              groupOptions={groupOptions}
              autocompletionsLoading={autocompletionsLoading}
              control={control}
              validationSetter={setValidation('user_groups')}
              setValue={setValue}
              trigger={trigger}
            />
          ),
        },
        {
          name: 'Read-Write Groups',
          valueComponent:
            !isAppEnvsEmpty('aad_readwrite_groups') &&
            tabledK8sApiAccess('aad_readwrite_groups'),
          description:
            'Group members are granted read-write access to the Kubernetes namespaces. At least one group is required.',
          emptyDescription:
            operation !== Operations.Create
              ? 'The application does not have any read-write groups assigned to it.'
              : 'Could not preselect a group to grant read-write access. Please select an existing group or create a new one to to grant read-write access to your namespaces.',
          editComponent: false,
        },
      ],
    ],
  ];

  const canCreate = () => {
    return (
      isConfigurationValid() &&
      !formSubmitted &&
      !applicationResponseLoading &&
      applicationResponseError === undefined &&
      formError === undefined
    );
  };

  const canEdit = () => {
    const { status } = applicationResponse;

    return (
      isConfigurationValid() &&
      !formSubmitted &&
      !applicationResponseLoading &&
      applicationResponseError === undefined &&
      formError === undefined &&
      (status === 'applied' || status === 'unknown')
    );
  };

  const handleCreateAppNS = () => {
    if (!canCreate()) return;
    sendRequest(false, ({ stargate_project_id, stargate_resource_key }) => {
      navigate(
        `/mtfuji/project/${stargate_project_id}/application/${stargate_resource_key}`,
      );
    });
  };

  const checkApplicationStatus = async (): Promise<boolean> => {
    try {
      const appResp = await mtfujiApi.getApplication(
        currentStargateProjectId,
        applicationName,
      );
      setApplicationResponse(appResp);
      if (appResp.status === 'applied') {
        return true;
      }
    } catch (error: any) {
      setApplicationResponseError(error as Error);
      return true;
    }
    return false;
  };

  const refreshInterval = 15000; // milliseconds
  const handleEditAppNS = () => {
    if (!canEdit()) return;
    sendRequest(false, () => {
      if (applicationResponseError) {
        return;
      }
      setApplicationResponseLoading(true);
      const pollID: any = setInterval(() => {
        checkApplicationStatus().then(done => {
          if (done) {
            setApplicationResponseLoading(false);
            clearInterval(pollID);
          }
        });
        return () => clearInterval(pollID);
      }, refreshInterval);
    });
  };

  const getEditTitle = (step: number) => {
    let title = '';

    if (overviewCards[step]) {
      title = overviewCards[step][0];
    }

    return title;
  };

  const getEditItems = (step: number) => {
    let items = new Array<OverviewItem>();

    if (overviewCards[step]) {
      items = overviewCards[step][1];
    }

    return items;
  };

  const selectProject = (
    project: NamespaceAutocompletionResponseProjectType,
  ) => {
    reset();
    setApplicationResponse({} as ApplicationResponse);
    setCurrentStargateProject(project);
    setValue('request.stargate_project_id', project.id);
    trigger('request.stargate_project_id');
    setConfirmProjectSelection(undefined);
    setCurrentStargateProjectId(project.id);
  };

  const projectOnSelect = (
    project: NamespaceAutocompletionResponseProjectType,
  ) => {
    if (currentStargateProject.id === project.id) return;
    if (Object.keys(formState.touchedFields).length > 0) {
      setConfirmProjectSelection(project);
      return;
    }
    selectProject(project);
  };

  useEffect(() => {
    async function refresh() {
      setAutocompletionsLoading(true);
      try {
        const projId = currentStargateProjectId;
        const resp = await mtfujiApi.getNamespaceAutocompletions(projId);

        const idToken = await authApi.getIdToken();
        const userRes: any = await projectApi.getUserDetails(
          { idToken: idToken },
          { include: 'all' },
        );
        if (userRes.response?.status !== 200) {
          throw new Error('failed to get user details');
        }
        const userData = userRes.response.data;

        // Uses autocompletions to set project options
        // as it already accounts for user create permissions
        setProjectOptions(resp.projects);

        // projId is 0 when called from mtfuji/new
        // anything requiring a valid projId must be set in this block
        if (!!projId) {
          const [artAc, ghAc, ugAc] = await getSGResources();
          setRepositoryOptions(ghAc);
          setArtifactoryRepositoryOptions(artAc);
          setGroupOptions(ugAc);
          setClusterOptions(resp.clusters);

          // Uses all projects from user details to set current project details
          const currentProj: any = userData?.projects.find(
            (p: any) => p.id === projId,
          );
          if (currentProj === undefined) {
            throw Error(`Project ID '${projId}' does not exist`);
          }
          setCurrentStargateProject(currentProj);

          // checking if this is a preview page
          if (operation !== Operations.Create) {
            // trying to view application namespace, get application from api
            try {
              const application: ApplicationResponse = await retry(
                async (_, retryCount) => {
                  setGetAppProgress(retryCount * 10);

                  return await mtfujiApi.getApplication(
                    currentStargateProjectId,
                    applicationName,
                  );
                },
                { retries: 10 },
              );

              setApplicationResponse(application);

              // application found, setValue all fields
              // for the moment, we only can set values for the baseconfig `prod` env
              // TODO: update this later when we have per env settings, might need to remove this
              const appNSConfigBaseKey = application.configuration_base_key;
              const baseConfig = application.configuration[appNSConfigBaseKey];

              const rwAADGroups: ApplicationEnvAADGroup[] =
                baseConfig.aad_readwrite_groups;
              setValue(
                'developer_group',
                isEmptyOrNil(rwAADGroups) ? [] : rwAADGroups,
              );

              const roAADGroups: ApplicationEnvAADGroup[] =
                baseConfig.aad_readonly_groups;
              setValue(
                'readonly_group',
                isEmptyOrNil(roAADGroups) ? [] : roAADGroups,
              );

              // This was created because there are already appNS that has different env config
              setApplicationNamespaceEnvs(application.configuration);

              setValue('app_name', application.app_name);

              setValue(
                'active_clusters',
                resp.clusters.filter(c =>
                  application?.active_clusters.some(
                    o =>
                      `${o.workload_prefix}-${o.pair_index}-${o.region}` ===
                      c.id,
                  ),
                ),
              );

              if (!isEmptyOrNil(application.github_repositories)) {
                setValue(
                  'github_repository',
                  application.github_repositories[0],
                );
              }

              application.artifactory_registries.forEach(r => {
                // Set the name of the dev tool based on data from CORE as mtfuji-api does not have this information
                const artDevTool = artAc.find(
                  x => x.key === r.key && x.dev_tool_id === r.dev_tool_id,
                );
                if (artDevTool) {
                  r.name = artDevTool?.name || '';
                }

                if (r.dev_tool_id === ARTIFACTORY_SAAS_DEV_TOOL_ID) {
                  setValue('artifactory_registries.0', r);
                } else if (r.dev_tool_id === ARTIFACTORY_DEV_TOOL_ID) {
                  setValue('artifactory_registries.1', r);
                }
              });

              setValue('request.sha', application.sha);

              // Does not show Pending component if application has already been created or is being updated
              if (!['applied', 'pending_change'].includes(application.status)) {
                setActiveCategory(overviewCards.length);
              }

              // Disable edit button if application is being created or updated
              if (
                ['pending_create', 'pending_change'].includes(
                  application.status,
                )
              ) {
                setEnableEditableButton(false);
              }
            } catch (error: any) {
              setEnableEditableButton(false);
              setFormError(error as Error);
              return;
            }
          }

          // generate default app name
          const appName = getValues('app_name');
          if (appName === '') {
            const name = generateRandomName(currentProj);
            setValue('app_name', name);
          }

          let clusters = getValues('active_clusters');
          if (clusters.length === 0) {
            // set cluster(s) based on URL params
            resp.clusters.forEach(c => {
              if (queryClusterIDs && c.id in queryClusterIDs) {
                clusters.push(c);
              }
            });

            // default to latest EKS in ap-northeast-1
            // not great option because if users follow the default
            // ap-northeast-1 becomes a major SPOF
            // but we have no data point to make a better decision
            if (clusters.length === 0) {
              let gcLatest: ApiConfigClusterType | undefined = undefined;
              resp.clusters.forEach(c => {
                if (c.region !== 'ap-northeast-1') {
                  return;
                }

                if (c.name_prefix.startsWith('gc-')) {
                  if (!gcLatest) {
                    gcLatest = c;
                  }

                  const curr = Number(c.name_prefix.substring(3));
                  const latest = Number(gcLatest.name_prefix.substring(3));

                  if (curr > latest) {
                    gcLatest = c;
                  }
                }
              });
              if (gcLatest) {
                clusters = [gcLatest];
              }
            }

            setValue('active_clusters', clusters);
          }

          const gitRepo = getValues('github_repository');
          if (gitRepo.host === '') {
            gitRepo.host = 'github.com';
          }

          if (gitRepo.owner === '') {
            // find GitHub EMU org to use
            ghAc.forEach(r => {
              // if form has values set (from URL params)
              // and a matching entry exists in response
              if (r.host === gitRepo.host && r.owner === gitRepo.owner) {
                return;
              }

              // find org matching project key
              if (
                r.host === gitRepo.host &&
                r.owner === currentProj.key.toLowerCase()
              ) {
                gitRepo.owner = r.owner;
                return;
              }
            });

            // if there is more than one org but only one is on EMU
            if (gitRepo.owner === '' && ghAc.length > 0) {
              const emuOptions = ghAc.filter(r => r.host === 'github.com');
              if (emuOptions.length === 1) {
                gitRepo.dev_tool_id = GITHUBEMU_DEV_TOOL_ID;
                gitRepo.owner = emuOptions[0].owner;
                gitRepo.key = emuOptions[0].owner;
                gitRepo.host = emuOptions[0].host;
                gitRepo.name = emuOptions[0].name;
              }
            }

            // if there is only one org, use it
            if (gitRepo.owner === '' && ghAc.length === 1) {
              gitRepo.dev_tool_id =
                ghAc[0].host === 'github.com'
                  ? GITHUBEMU_DEV_TOOL_ID
                  : GITHUB_DEV_TOOL_ID;
              gitRepo.owner = ghAc[0].owner;
              gitRepo.key = ghAc[0].owner;
              gitRepo.host = ghAc[0].host;
              gitRepo.name = ghAc[0].name;
            }
          }

          if (gitRepo.name === '') {
            // set app_name as default repository.name
            gitRepo.name = getValues('app_name');
          }

          setValue('github_repository', gitRepo);

          if (
            getValues('artifactory_registries.0.key') === '' &&
            operation === Operations.Create
          ) {
            // find or create container registry
            artAc.forEach(r => {
              // if form has values set (from URL params)
              // and a matching entry exists in response
              if (r.key === getValues('artifactory_registries.0.key')) {
                setValue('artifactory_registries.0', r);
                return;
              }

              // find registry matching project key
              if (r.key === currentProj.key.toLowerCase()) {
                setValue('artifactory_registries.0', r);
                return;
              }
            });

            // if there is only one image registry, use it
            if (
              getValues('artifactory_registries.0.key') === '' &&
              artAc.length === 1
            ) {
              setValue('artifactory_registries.0', artAc[0]);
            }
          }

          const defaultReadWriteGroupName = getDefaultReadWriteGroupName(
            getValues('app_name'),
          );
          let readWriteGroups = getValues('developer_group');
          if (readWriteGroups.length === 0 && queryReadWriteGroups) {
            // set read-write group based on URL param
            readWriteGroups = ugAc.filter(g => g.uuid in queryReadWriteGroups);
          }

          setValue(
            'developer_group',
            readWriteGroups.length > 0
              ? readWriteGroups
              : ugAc.filter(g => g.name === defaultReadWriteGroupName),
          );

          const defaultReadOnlyGroupName = getDefaultReadOnlyGroupName(
            getValues('app_name'),
          );
          let readOnlyGroups = getValues('readonly_group');
          if (readOnlyGroups.length === 0 && queryReadOnlyGroups) {
            // set read-write group based on URL param
            readOnlyGroups = ugAc.filter(g => g.uuid in queryReadOnlyGroups);
          }

          setValue(
            'readonly_group',
            readOnlyGroups.length > 0
              ? readOnlyGroups
              : ugAc.filter(g => g.name === defaultReadOnlyGroupName),
          );
        }
      } catch (error: any) {
        setFormError(error as Error);
      } finally {
        setAutocompletionsLoading(false);
        overviewRef.current?.scrollIntoView({ behavior: 'smooth' });
        handlePreview();
      }
    }

    refresh();
    return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStargateProjectId]);

  return (
    <>
      {formError && <Alert severity="error">{formError.message}</Alert>}
      <Layout
        title={
          operation === Operations.Create
            ? 'Create Application Namespaces'
            : 'Application Namespace'
        }
        className="mtfuji_wizard"
      >
        {autocompletionsLoading ? (
          <>
            <Grid container spacing={3} direction="column">
              {/* Overview grid */}
              <Grid item>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Progress
                      {...(operation !== Operations.Create
                        ? {
                            variant: 'determinate',
                            value: getAppProgress,
                          }
                        : {})}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Skeleton animation="wave" height={80} />
                  </Grid>
                  {overviewCards.map((_, i) => {
                    return (
                      <Grid item xs={6} key={`sekelton-${i}`}>
                        <Skeleton animation="wave" height={50} />
                        <Skeleton
                          animation="wave"
                          variant="rect"
                          height={200}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            {!currentStargateProjectId && (
              <Grid
                container
                spacing={3}
                direction="column"
                id="mtfuji_wizard_select_project"
              >
                <Grid item xs={12}>
                  <ProjectSelector
                    stargateProject={currentStargateProject}
                    onSelect={projectOnSelect}
                    projectOptions={projectOptions}
                    autocompletionsLoading={autocompletionsLoading}
                    enableEditableButton={!enableEditableButton}
                  />
                </Grid>
              </Grid>
            )}

            {currentStargateProject.id !== undefined && (
              <>
                <Grid
                  container
                  spacing={6}
                  direction="column"
                  id="mtfuji_wizard_overview"
                >
                  {/* Overview grid */}
                  <Grid item>
                    {activeCategory === overviewCards.length && (
                      <Grid container spacing={3} ref={pendingRef}>
                        <Grid item xs={12}>
                          <Pending
                            projectId={currentStargateProjectId}
                            applicationName={getValues('app_name')}
                            applicationResponse={applicationResponse}
                            applicationResponseError={applicationResponseError}
                            maybeNewGithub={maybeNewGithub}
                            onFinishedPending={handleFinishedPending}
                          />
                        </Grid>
                      </Grid>
                    )}
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        {activeCategory === overviewCards.length ||
                        operation !== Operations.Create ? (
                          <></>
                        ) : (
                          <Alert severity="info">
                            Default configuration values have been set to help
                            you get started. Please verify the default values
                            and modify them as required.
                          </Alert>
                        )}
                      </Grid>
                    </Grid>
                    <Grid container spacing={6}>
                      {overviewCards.map((card, i) => {
                        return (
                          <Grid item xs={6} key={`card-container-${i}`}>
                            <Typography variant="h6">{card[0]}</Typography>
                            <OverviewCard
                              id={`card-${i}`}
                              actionsDisabled={!enableEditableButton}
                              items={card[1]}
                              onEdit={
                                showEditUI
                                  ? () => {
                                      handleEdit(i);
                                    }
                                  : undefined
                              }
                            />
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Grid>
                  {operation === Operations.Create && (
                    <>
                      <Grid item xs={12}>
                        <Preview
                          applicationResponse={applicationResponse}
                          loading={applicationResponseLoading}
                          error={applicationResponseError}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          type="submit"
                          variant="contained"
                          className="submit-button"
                          onClick={handleCreateAppNS}
                          style={{ float: 'right' }}
                          disabled={!canCreate()}
                        >
                          Create Application Namespace
                        </Button>
                      </Grid>
                    </>
                  )}
                  {showEditUI && operation === Operations.Update && (
                    <>
                      <Grid item xs={12}>
                        <Preview
                          applicationResponse={applicationResponse}
                          loading={applicationResponseLoading}
                          error={applicationResponseError}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          variant="contained"
                          color="primary"
                          className="submit-button"
                          onClick={handleEditAppNS}
                          style={{ float: 'right' }}
                          disabled={!canEdit()}
                        >
                          Update Application Namespace
                        </Button>
                      </Grid>
                    </>
                  )}
                </Grid>

                {/* Edit grid */}
                <Dialog
                  maxWidth="lg"
                  open={
                    activeCategory !== -1 &&
                    activeCategory < overviewCards.length
                  }
                  scroll="paper"
                  aria-labelledby="scroll-dialog-title"
                  aria-describedby="scroll-dialog-description"
                  className="mtfuji_wizard"
                  fullWidth
                >
                  <DialogTitle id="scroll-dialog-title">
                    Configure {getEditTitle(activeCategory)}
                  </DialogTitle>
                  <DialogContent dividers>
                    <DialogContentText
                      id="scroll-dialog-description"
                      tabIndex={-1}
                      color="initial"
                    >
                      <Grid
                        container
                        className={classes.dialogGrid}
                        spacing={3}
                        direction="column"
                        id="mtfuji_wizard_edit"
                      >
                        {/* Overview grid */}
                        <Grid item>
                          <Grid container spacing={3}>
                            <Grid container spacing={3}>
                              {getEditItems(activeCategory).map(item => {
                                return (
                                  item.editComponent && (
                                    <Grid item xs={12}>
                                      {item.editComponent}
                                    </Grid>
                                  )
                                );
                              })}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleSet} color="primary">
                      Save & Close
                    </Button>
                  </DialogActions>
                </Dialog>

                {/* Select project confirmation */}
                <Dialog
                  maxWidth="sm"
                  aria-labelledby="confirmation-dialog-project-select"
                  open={!!confirmProjectSelection}
                  className="mtfuji_wizard"
                >
                  <DialogTitle id="confirmation-dialog-project-select">
                    Switch Project?
                  </DialogTitle>
                  <DialogContent dividers>
                    <Typography>
                      Switching projects from{' '}
                      <code style={{ color: '#f44336' }}>
                        {currentStargateProject.name}
                      </code>{' '}
                      to{' '}
                      <code style={{ color: '#4caf50' }}>
                        {confirmProjectSelection?.name}
                      </code>{' '}
                      will reset the form and preset new defaults. The changes
                      you made will be lost.
                    </Typography>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => {
                        setConfirmProjectSelection(undefined);
                      }}
                      variant="contained"
                      color="primary"
                    >
                      Nevermind
                    </Button>
                    <Button
                      onClick={() => {
                        return (
                          !!confirmProjectSelection &&
                          selectProject(confirmProjectSelection)
                        );
                      }}
                      color="secondary"
                    >
                      Yes, switch project
                    </Button>
                  </DialogActions>
                </Dialog>
              </>
            )}
          </>
        )}
      </Layout>
    </>
  );
};
