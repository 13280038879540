import React, { useState } from 'react';
import {
  Button,
  Grid,
  InputLabel,
  TextField,
  DialogContent,
  Dialog,
} from '@material-ui/core';
import {
  errorApiRef,
  microsoftAuthApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import {
  RESOURCE_NAME_MAX_LENGTH,
  RESOURCE_DESCRIPTION_MAX_LENGTH,
  RESOURCE_NAME_MIN_LENGTH,
  SPECIAL_RESOURCE_NAME_REGEX,
  ARTIFACTORY_DEV_TOOL_ID,
  ARTIFACTORY_SAAS_DEV_TOOL_ID,
} from 'usg-types';
import { projectApiRef } from '@internal/plugin-projects';
import { useForm } from 'react-hook-form';
import { definitions } from '../../api';

type NamespaceAutocompletionResponseProject =
  definitions['handlers.NamespaceAutocompletionResponseProject'];

type ArtifactoryInput = {
  repo_name: string;
  description: string;
};

type allowedDevTools =
  | typeof ARTIFACTORY_SAAS_DEV_TOOL_ID
  | typeof ARTIFACTORY_DEV_TOOL_ID;
export type CreateArtifactoryDialogProps = {
  stargateProject: NamespaceAutocompletionResponseProject;
  open: boolean;
  handleDialogClose: (data?: any) => void;
  devTool: allowedDevTools;
  defaultValues?: ArtifactoryInput;
};
export const CreateArtifactoryDialog = (
  props: CreateArtifactoryDialogProps,
) => {
  const { open, handleDialogClose, stargateProject, devTool, defaultValues } =
    props;
  const [nameDirty, setNameDirty] = useState(false);
  const errorApi = useApi(errorApiRef);
  const projectApi = useApi(projectApiRef);
  const authref = useApi(microsoftAuthApiRef);
  const artifactoryInstanceName =
    devTool === ARTIFACTORY_SAAS_DEV_TOOL_ID
      ? 'Artifactory (SaaS)'
      : 'Artifactory (Self-hosted) (deprecated)';

  const {
    register,
    handleSubmit,
    formState: { isValid },
    reset: resetForm,
  } = useForm<ArtifactoryInput>({
    mode: 'onChange',
    defaultValues: defaultValues,
  });

  const onSubmit = async (data: ArtifactoryInput) => {
    const idToken = await authref.getIdToken();
    const resp: any = await projectApi.createNewProjectResource(
      stargateProject.id.toString(),
      {
        idToken: idToken,
        key: data.repo_name.toLowerCase(),
        name: data.repo_name,
        description: data.description,
        dev_tool_id: devTool,
        config: {
          packageType: 'docker',
          rclass: 'local',
          ...(devTool === ARTIFACTORY_SAAS_DEV_TOOL_ID
            ? {
                rclass: 'federated',
                sites: ['jp', 'us'],
              }
            : {}),
        },
      } as any,
    );
    const code = resp.response?.status;
    if (code >= 400) {
      const errorMsg = resp?.response?.data?.message;
      errorApi.post(new Error(`${errorMsg}`));
      return;
    }
    handleDialogClose(resp.response?.data);
    setNameDirty(false);
    resetForm();
  };

  return (
    <Dialog open={open} onClose={() => handleDialogClose()} maxWidth="sm">
      <DialogContent id="rt-create-confirmed-dialog">
        <div style={{ paddingBottom: '24px' }}>
          <h1>Create Container Registry {artifactoryInstanceName}</h1>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <p>
                This will create a repository on {artifactoryInstanceName}{' '}
                instance.
              </p>
            </div>
            <div>
              <InputLabel error={!isValid} htmlFor="repo_name">
                {artifactoryInstanceName} Repo Name*
              </InputLabel>
              <TextField
                style={{ paddingBottom: '0%' }}
                type="text"
                id="repo_name"
                error={!isValid && nameDirty}
                onClick={() => setNameDirty(true)}
                fullWidth
                helperText={`Provide a unique name for your ${artifactoryInstanceName} repo. Only letters, numbers and single hyphens allowed. Must not end with hyphen. No. of Characters allowed Min=${RESOURCE_NAME_MIN_LENGTH.artifactory}, Max=${RESOURCE_NAME_MAX_LENGTH.artifactory}`}
                {...register('repo_name', {
                  required: true,
                  pattern: SPECIAL_RESOURCE_NAME_REGEX.artifactory,
                  minLength: RESOURCE_NAME_MIN_LENGTH.artifactory,
                  maxLength: RESOURCE_NAME_MAX_LENGTH.artifactory,
                })}
              />
            </div>
            <div style={{ marginTop: '16px' }}>
              <InputLabel htmlFor="description">Description</InputLabel>
              <TextField
                type="text"
                id="description"
                maxRows={4}
                minRows={4}
                multiline
                fullWidth
                helperText={`Provide a brief description for your ${artifactoryInstanceName} repo. Number of characters allowed Max = ${RESOURCE_DESCRIPTION_MAX_LENGTH}`}
                placeholder="Example : Repository for storing the artifacts for development within the One-click delivery automation solutions project."
                {...register('description', {
                  maxLength: RESOURCE_DESCRIPTION_MAX_LENGTH,
                })}
              />
            </div>
            <div style={{ paddingTop: '5%' }}>
              <Grid container spacing={2}>
                <Grid item xs={1}>
                  <Button
                    variant="outlined"
                    type="reset"
                    onClick={() => handleDialogClose()}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={2} />
                <Grid item xs={1}>
                  <Button
                    id="create-rt-submit-button"
                    variant="contained"
                    type="submit"
                    disabled={!isValid}
                  >
                    Create
                  </Button>
                </Grid>
              </Grid>
            </div>
          </form>
        </div>
      </DialogContent>
    </Dialog>
  );
};
